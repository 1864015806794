import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { UIButton, UIIcon, UILayout, UIText, UITextField } from 'bora-material-ui'
import messages from '../../../../../consts/messages'
import { translate } from '../../../../Common/Translator'
import Directions from '../../../containers/TripHeaderDouble/Layout/Directions'
import { splitTitle, variableToString } from '../../../../../utils/strings'
import { MessageBox } from '../../../../Common/components/MessageBox'

const Wrapper = styled.div`
  position: relative;
  margin-top: 10px;
`
const StaticPlaceholder = styled.span`
  position: absolute;
  font-size: 18px;
  pointer-events: none;
  right: 10px;
  top: 25%;
  color: #79909b;
  font-weight: 600;
  font-family: Roboto, sans-serif;
`
const xsStyles = {
  button: { margin: '0px', errorBackground: '#b42a2a' },
}
const actionButtonsStyles = {
  'j-flex-space-between': true,
}

const styles = {
  textField: { margin: '0 20px 0 0' },
  contentWrapper: {
    padding: '0 0 18px',
    margin: '0px 0px 10px 0px',
    width: '100%',
    // borderBottom: '1px solid #bfc3c7',
  },
  colorFailed: { color: '#b42a2a' },
}
class VehicleInputWeight extends React.PureComponent {
  state = {
    valueFirst: null,
    valueSecond: null,
    hasError: false,
  }

  static contextTypes = {
    muiTheme: PropTypes.object.isRequired,
  }

  static defaultProps = {
    value: false,
    disabled: false,
  }

  handleChangeFirst = (e) => {
    this.setState({
      ...this.state,
      valueFirst: e,
    })
    this.setState((state) => {
      if (
        (state.hasError && state.valueFirst !== '0' && !state.valueSecond) ||
        (state.hasError && state.valueFirst !== '0' && state.valueSecond !== '0')
      ) {
        return {
          hasError: !state.hasError,
        }
      } else if (!state.hasError && state.valueFirst === '0') {
        return {
          hasError: true,
        }
      }
      return state
    })
    return null
  }

  handleChangeSecond = (e) => {
    this.setState({
      ...this.state,
      valueSecond: e,
    })
    this.setState((state) => {
      if (state.hasError && state.valueFirst !== '0' && state.valueSecond !== '0') {
        return {
          hasError: !state.hasError,
        }
      } else if (!state.hasError && state.valueSecond === '0') {
        return {
          hasError: true,
        }
      }
      return state
    })
    return null
  }

  handleSaveChanges = () => {
    const { valueFirst, valueSecond } = this.state
    const arrayToSave = [valueFirst, valueSecond]
      .filter((item) => item && typeof item !== 'undefined')
      .map((item) => Number(item))
    if (
      Boolean(
        arrayToSave.find((item) => {
          return item < this.props.curbWeightInKg
        })
      ) ||
      arrayToSave.length < this.props.routeTitles.length
    ) {
      this.setState({
        hasError: true,
      })
      return
    }
    this.props.onSave(arrayToSave)
    this.props.onClose()
  }

  render() {
    const { disabled, routeTitles, onClose, curbWeightInKg } = this.props
    const { muiTheme } = this.context
    const { valueFirst, valueSecond, hasError } = this.state
    return (
      <UILayout column style={styles.contentWrapper}>
        <UILayout column margin="10px 0">
          {routeTitles &&
            routeTitles.map((item, idx) => (
              <UILayout key={idx} column width="100%" margin="10px 0">
                <Directions labelFrom={splitTitle(item)[0]} labelDestination={splitTitle(item)[1]} />
                <Wrapper>
                  <UITextField
                    readOnly={disabled}
                    change={idx === 0 ? this.handleChangeFirst : this.handleChangeSecond}
                    id={idx === 0 ? variableToString({ valueFirst }) : variableToString({ valueSecond })}
                    value={idx === 0 ? this.state.valueFirst : this.state.valueSecond}
                    type={'number'}
                    style={styles.textField}
                    inputStyle={hasError && styles.colorFailed}
                    hintText={''}
                    data-testid="input-weight-insert"
                  />
                  <StaticPlaceholder>kg</StaticPlaceholder>
                </Wrapper>
              </UILayout>
            ))}
          <UILayout row {...actionButtonsStyles} margin="10px 0">
            <UIButton
              disabled={false}
              onClick={onClose}
              height="auto"
              width="48%"
              borderRadius="2px"
              xs={xsStyles.button.margin}
              background={xsStyles.button.errorBackground}
            >
              <UILayout
                data-testid="cancel-weight-insert"
                row
                j-flex-center
                minHeight="37px"
                height="100%"
                width="100%"
                bgColor="transparent"
                center
              >
                <UIText
                  size="19px"
                  color="white"
                  padding="13px 15px"
                  textTransform="uppercase"
                  letterSpacing="0.6px"
                  lineHeight="1.16"
                  translate={messages.discardEdit}
                  font={muiTheme.secondFontFamilyDem}
                />
                <UIIcon
                  style={{ cursor: 'pointer' }}
                  type="iconClose"
                  width="14px"
                  height="14px"
                  fill="#fff"
                  margin="0 5px 0 0"
                />
              </UILayout>
            </UIButton>
            <UIButton
              disabled={hasError}
              onClick={this.handleSaveChanges}
              height="auto"
              width="48%"
              borderRadius="2px"
              xs={xsStyles.button.margin}
            >
              <UILayout
                data-testid="confirm-weight-insert"
                row
                j-flex-center
                minHeight="37px"
                height="100%"
                width="100%"
                bgColor="transparent"
                center
              >
                <UIText
                  size="19px"
                  color="white"
                  padding="13px 15px"
                  textTransform="uppercase"
                  letterSpacing="0.6px"
                  lineHeight="1.16"
                  translate={messages.saveEdit}
                  font={muiTheme.secondFontFamilyDem}
                />
                <UIIcon
                  style={{ cursor: 'pointer' }}
                  type="check"
                  width="14px"
                  height="14px"
                  fill="#fff"
                  margin="0 5px 0 0"
                />
              </UILayout>
            </UIButton>
          </UILayout>
        </UILayout>
        <UILayout>
          <MessageBox
            display-if={hasError}
            margin="10px 0 10px 0"
            text={translate(messages.invalidWeight, { curbWeightInKg })}
            type="error"
          />
        </UILayout>
      </UILayout>
    )
  }
}
VehicleInputWeight.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  routeTitles: PropTypes.array.isRequired,
  curbWeightInKg: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  inputStyle: PropTypes.string,
}

export default VehicleInputWeight
