import React from 'react'
import PropTypes from 'prop-types'
import { UILayout } from 'bora-material-ui'

import messages from '../../../../../../../consts/messages'
import EditModeButton from './EditModeButton'

import RowInfo from './RowInfo'
import { isLiinilaevad } from '../../../../../../../utils/liinilaevadThemeUtils'

const xsStyles = {
  column: { column: 'true' },
  marginBottom15: { margin: '0 0 15px 0' },
  width100ChildrenBottomStyles: { width: '100%', childrenStyle: { margin: '0 0 10px 0' } },
  columnChildrenBottomStyles: { column: 'true', childrenStyle: { margin: '0 0 10px 0' } },
  width100WithoutPaddingAndBorders: { width: '100%', border: '0!important', padding: '0!important' },
}

const PassengerData = ({ onEdit, editMode, lockEdit, phoneNumber, email, name, userNeedToPayAfterEdit }, { intl }) => {
  const T = intl.formatMessage

  return (
    <UILayout
      data-testid="passenger-wrap"
      column
      // j-flex-end
      flex-end
      position="relative"
      sm={xsStyles.width100WithoutPaddingAndBorders}
    >
      <EditModeButton display={editMode && !userNeedToPayAfterEdit} lock={lockEdit} onEdit={onEdit} />
      <UILayout column childrenStyle={xsStyles.marginBottom15} xs={xsStyles.width100ChildrenBottomStyles}>
        <RowInfo
          id="customer-info-name"
          shouldCapitalize={isLiinilaevad}
          label={T(messages.fullName)}
          value={name}
          editMode={editMode}
        />
        <RowInfo id="customer-info-phone" label={T(messages.contactPhone)} value={phoneNumber} editMode={editMode} />
        <RowInfo
          id="customer-info-email"
          label={T(messages.email)}
          value={email}
          showIcon={!lockEdit}
          editMode={editMode}
        />
      </UILayout>
    </UILayout>
  )
}

PassengerData.propTypes = {
  onEdit: PropTypes.func.isRequired,
  editMode: PropTypes.any,
  lockEdit: PropTypes.any,
  phoneNumber: PropTypes.any.isRequired,
  email: PropTypes.any.isRequired,
  name: PropTypes.any.isRequired,
}

PassengerData.defaultProps = {
  editMode: false,
  lockEdit: false,
}

PassengerData.contextTypes = {
  intl: PropTypes.any,
}

export default PassengerData
