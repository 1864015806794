import { auth } from '../../services'
import { goToPage } from '../../actions'

const {
  activate,
  authByToken,
  cardIdPluginFailed,
  logout,
  recover,
  signin,
  signout,
  signup,
  resetPassword,
  signinFailed,
  tokenCheckFailed,
} = auth.actions

export const actions = {
  activate,
  authByToken,
  cardIdPluginFailed,
  logout,
  goToPage,
  recover,
  signin,
  signout,
  signup,
  resetPassword,
  signinFailed,
  tokenCheckFailed,
}

const { getChallengeID, getError, getFetching, getMessage, getToken } = auth.selectors

export const selectors = {
  getChallengeID,
  getError,
  getFetching,
  getMessage,
  getToken,
}
