import React from 'react'
import PropTypes from 'prop-types'
import { THEME } from 'bora-material-ui/themes'

import { UILayout, UIText, UIIcon } from 'bora-material-ui'

import BuyProgressRouter from './BuyProgressRouter'

import messages from '../../../../../consts/messages'
import UIElements from '../../../../../consts/UIElements'

const styles = {
  shedulesXs: { textDecoration: 'none' },
  mainInsideXs: { height: '60px' },
  mainInsideNavWrapperXs: { height: '60px', padding: '0 20px' },
  buyProgressRouterXs: { display: 'none' },
  priceWrapperXs: { column: false, row: true },
  priceScheduleWraperXs: { width: '100%' },
  priceLabelXs: { size: '20px', width: '100%', height: '100%', padding: '0' },
}

class StepsRouter extends React.PureComponent {
  static contextTypes = {
    muiTheme: PropTypes.object,
  }

  static propTypes = {
    locale: PropTypes.string.isRequired,
    errorOccurred: PropTypes.bool.isRequired,
    goToSchedules: PropTypes.func.isRequired,
    onPopStateChange: PropTypes.func.isRequired,
    selectTicketsStepAvailable: PropTypes.bool.isRequired,
    confirmAndPayStepAvailable: PropTypes.bool.isRequired,
  }

  componentDidMount() {
    const { onPopStateChange } = this.props

    window.onpopstate = () => {
      onPopStateChange()
    }
  }

  render() {
    const { muiTheme } = this.context
    const { locale, errorOccurred, selectTicketsStepAvailable, confirmAndPayStepAvailable } = this.props

    return (
      <UILayout
        row
        center
        height="84px"
        id={errorOccurred ? 'mainInsideError' : 'mainInside'}
        {...muiTheme.ids.mainInsideNav}
        smm={styles.mainInsideXs}
        data-testid="top"
      >
        <UILayout
          id="mainInsideNavWrapper"
          width={UIElements.CONTENT_WIDTH}
          j-flex-space-between
          height="84px"
          padding="10px 20px"
          style={{
            alignItems: 'inherit',
            background: window.brandProps.theme === THEME.LIINILAEVAD && muiTheme.ids.mainInside.style.navBackground,
          }}
          smm={styles.mainInsideNavWrapperXs}
        >
          <UILayout
            display-if={muiTheme.ids.priceScheduleWraper}
            data-testid="nav-schedules"
            column
            {...muiTheme.ids.priceScheduleWraper}
            smm={styles.priceScheduleWraperXs}
          >
            <a onClick={this.props.goToSchedules} style={styles.shedulesXs}>
              <UILayout
                column
                center
                width="auto"
                cursor="pointer"
                path={`/${locale}/main-inside/select-tickets`}
                {...muiTheme.ids.priceWraper}
                smm={styles.priceWrapperXs}
              >
                <UIIcon
                  minWidth="30px"
                  type={`iconPricelist_${UIElements.LOGO}`}
                  {...muiTheme.ids.mainInsideNavIconPrice}
                />
                <UIText
                  size="14px"
                  color={muiTheme.palette.accent1Color}
                  translate={messages.pricelist}
                  {...muiTheme.ids.mainInsideNavText}
                  width="auto"
                  {...muiTheme.ids.priceLabel}
                  smm={styles.priceLabelXs}
                />
              </UILayout>
            </a>
          </UILayout>

          <UILayout smm={styles.buyProgressRouterXs}>
            <BuyProgressRouter error={errorOccurred} smm={styles.buyProgressRouterXs}>
              <UIText
                path={`/${locale}/main-inside/select-datetime`}
                size="0.875em"
                color={muiTheme.palette.accent1Color}
                whiteSpace="nowrap"
                translate={messages.destination}
                {...muiTheme.ids.mainInsideNavText}
              />
              <UIText
                path={`/${locale}/main-inside/select-tickets`}
                size="0.875em"
                color={muiTheme.palette.accent1Color}
                whiteSpace="nowrap"
                translate={messages.selectTicketStep}
                {...muiTheme.ids.mainInsideNavText}
                disabled={!selectTicketsStepAvailable}
              />
              <UIText
                path={`/${locale}/main-inside/confirm`}
                size="0.875em"
                color={muiTheme.palette.accent1Color}
                whiteSpace="nowrap"
                translate={messages.confirmAndPay}
                {...muiTheme.ids.mainInsideNavText}
                disabled={!confirmAndPayStepAvailable}
              />
            </BuyProgressRouter>
            <UILayout
              display-if={muiTheme.ids.mainInsideNavIconHelp}
              width="auto"
              column
              flex-end
              j-flex-center
              {...muiTheme.ids.priceScheduleWraper}
            >
              <a style={styles.shedulesXs}>
                <UILayout
                  column
                  center
                  width="auto"
                  cursor="pointer"
                  path={`/${locale}/main-inside/select-tickets`}
                  {...muiTheme.ids.helpWraper}
                >
                  <UIIcon
                    minWidth="30px"
                    type={`iconHelp_${UIElements.LOGO}`}
                    {...muiTheme.ids.mainInsideNavIconPrice}
                  />
                  <UIText
                    size="14px"
                    color={muiTheme.palette.accent1Color}
                    translate={messages.help}
                    {...muiTheme.ids.mainInsideNavText}
                    width="auto"
                    {...muiTheme.ids.helpLabel}
                  />
                </UILayout>
              </a>
            </UILayout>
          </UILayout>
        </UILayout>
      </UILayout>
    )
  }
}

export default StepsRouter
