import { mergeDeepRight } from 'ramda'
import UIElements from '../../../consts/UIElements'

const THEMES_STYLES = {
  KIHNU: {
    bgColor: '#4266bd',
    color: '#4266bd',
  },
  SEAJETS: {
    color: 'black',
  },
}

const defaultSeparatorStyles = {
  bgColor: '#e9e9e9',
  textTransform: 'uppercase',
}

export const separatorStyles = mergeDeepRight(defaultSeparatorStyles, THEMES_STYLES[UIElements.APP_NAME] || {})
