import React from 'react'
import { connect } from 'react-redux'

import { UILayout, UIText } from 'bora-material-ui'

import messages from '../../../../consts/messages'
import { translate } from '../../../Common/Translator'
import { Field, blur } from 'redux-form'
import PropTypes from 'prop-types'
import { InputField, DropDownField } from '../../../../formComponents'
import { required as validateRequired } from '../../../../utils/validate'
import { birthDatesOptions, birthMonthsOptions, birthYearsOptions } from './Passengers-Date.util'
import { setReservationPassengerInfo } from '../../../../actions'
import { getCitizenshipList } from '../../../../services/user/selectors'
import { take } from 'ramda'

const birthDayWrapStyleXs = { style: { flexDirection: 'column', alignItems: 'stretch', padding: 0 } }
const dateFldStyle = { minWidth: '100px', margin: '0 10px 0 0', padding: 0, width: '100%' }
const dateFldStyleXs = { minWidth: '75px', maxWidth: '80px' }
const yearFldStyle = { ...dateFldStyle, margin: 0 }
const yearFldStyleXs = { minWidth: '120px' }
const monthFldStyle = { ...dateFldStyle, minWidth: '150px' }

const labelStyle = { display: 'none' }
const inputContainerStyle = { padding: 0 }
const inputInnerStyle = { boxShadow: 'none', borderBottom: '1px solid rgb(191, 195, 199)' }

const commentsLabelStyles = {
  width: '120px',
  fontSize: '17px',
  fontWeight: 'normal',
  color: '#79909b',
  weight: 'normal',
}

const Buttons = (props) => {
  const {
    input: { onChange },
    index,
    formFields,
  } = props

  const currentSelection = formFields.get(index).gender
  return (
    <UILayout className="trailers-switcher" borderRadius="15px" margin="16px 0 0 0">
      <button
        className={
          !currentSelection || currentSelection === 'F'
            ? 'trailers-switcher__button'
            : 'trailers-switcher__button trailers-switcher__button_active'
        }
        onClick={() => {
          if (!currentSelection || currentSelection === 'F') {
            onChange('M')
          }
          return true
        }}
      >
        {translate(messages.male)[0]}
      </button>
      <button
        className={
          !currentSelection || currentSelection === 'M'
            ? 'trailers-switcher__button'
            : 'trailers-switcher__button trailers-switcher__button_active'
        }
        onClick={() => {
          if (!currentSelection || currentSelection === 'M') {
            onChange('F')
          }
          return true
        }}
      >
        {translate(messages.female)[0]}
      </button>
    </UILayout>
  )
}

const ButtonFields = ({ name, label, validate = [], required, placeholder, ...props }) => {
  const fieldProps = { required }
  const validateFunc = required ? [validateRequired, ...validate] : validate

  return (
    <Field
      name={name}
      type="text"
      label={label}
      component={Buttons}
      props={fieldProps}
      placeholder={placeholder}
      validate={validateFunc}
      {...props}
    />
  )
}

const CompanyItemTemplate = (props) => {
  if (!props) return null
  return (
    <UILayout height="44px" align="center">
      <UIText size="15px" color="black" text={props.title || props.value.title} />
    </UILayout>
  )
}

const NOT_A_COUNTRY_CODE = 'XXX'

const CountryItemTemplate = (props) => {
  if (!props) return null
  const { code, title } = props
  return (
    <UILayout height="44px" align="center">
      <UIText size="15px" color="black" text={code === NOT_A_COUNTRY_CODE ? '' : title} />
    </UILayout>
  )
}

const alignItemsFlexEnd = {
  alignItems: 'flex-end',
}

class PassengerEditForm extends React.Component {
  state = {
    list: [],
  }

  componentDidMount() {
    this.setInitialList()
  }

  setInitialList() {
    const { citizenships = [] } = this.props
    const citizenshipList = [...take(10)(citizenships), { code: NOT_A_COUNTRY_CODE, title: 'Load all' }]
    this.setState({
      list: citizenshipList,
    })
  }

  render() {
    const { muiTheme } = this.context
    const { FormFields = [], index, fields, passenger, citizenships = [] } = this.props
    const citizenshipList = this.state.list
    const errorStyles = { 'margin-top': '6px', 'font-size': '12px', 'font-family': muiTheme.secondFontFamily }

    const CitizenshipItemTemplate = ({ value }) => {
      const { code, title } = value
      const wrapperStyle = code === NOT_A_COUNTRY_CODE ? alignItemsFlexEnd : {}
      const onClick =
        code === NOT_A_COUNTRY_CODE
          ? (e) => {
              e.stopPropagation()
              e.nativeEvent.stopImmediatePropagation()
              this.setState({ list: citizenships })
            }
          : () => {}
      return (
        <UILayout height="44px" onClick={onClick} style={wrapperStyle}>
          <UIText size="15px" color="black" text={title} display-if={code !== NOT_A_COUNTRY_CODE} />
          <UIText
            size="14px"
            color="black"
            weight="bold"
            translate={messages.loadAllCitizenships}
            display-if={code === NOT_A_COUNTRY_CODE}
          />
        </UILayout>
      )
    }
    return (
      <React.Fragment>
        {fields.get(index).personalIdentificationNumber ? (
          <React.Fragment>
            <UILayout row center width="100%" xs={{ flexFlow: 'column' }}>
              <UIText padding="10px 0 0 0 " size="18px" color="#79909b" align="left" minWidth="112px">
                {translate(messages.citizenship)}
              </UIText>
              <UILayout row center width="100%" padding="10px 0 0 9px">
                <DropDownField
                  onChange={(e, value) => {
                    this.props.blur('bookingForm', `${passenger}.citizenship`, value)
                    this.setInitialList()
                  }}
                  name={`${passenger}.citizenship`}
                  required
                  hideStar
                  styles={{ minWidth: '120px', padding: 0, width: '100%' }}
                  labelContainerProps={{ display: 'none' }}
                  inputContainerProps={{ padding: 0 }}
                  inputStyles={{ boxShadow: 'none', borderBottom: '1px solid rgb(191, 195, 199)' }}
                  itemsArr={citizenshipList}
                  fullItemsList={citizenships}
                  itemTemplate={CitizenshipItemTemplate}
                  selectionTemplate={CountryItemTemplate}
                  errorStyles={errorStyles}
                />
              </UILayout>
            </UILayout>
            <UILayout column padding="12px 0 14px 0">
              <InputField
                key={`${passenger}.optionalInfo.resident`}
                hideStar
                name={`${passenger}.optionalInfo`}
                text={translate(messages.optionalInfo)}
                wrapperInputStyles={{ padding: '0' }}
                wrapperStyles={{ padding: '0', column: false }}
                errorStyles={errorStyles}
                style={{ boxShadow: '0 ', borderBottom: '1px solid #bfc3c7' }}
                wrapperLabelStyles={{ width: 'auto', 'flex-end': true, display: 'flex' }}
                labelStyles={{
                  width: '120px',
                  fontSize: '17px',
                  fontWeight: 'normal',
                  color: '#79909b',
                  weight: 'normal',
                }}
                {...muiTheme.ids.InputField}
              />
            </UILayout>
          </React.Fragment>
        ) : (
          FormFields.map((field) => {
            if (field === 'gender') {
              return (
                <UILayout padding="10px 0 0 0 ">
                  <UIText padding="20px 0 10px 0 " size="18px" color="#79909b" align="left" minWidth="112px">
                    {translate(messages[field])}
                  </UIText>
                  <UILayout width="104px">
                    <ButtonFields
                      onChange={(e, value) => {
                        this.props.blur('bookingForm', `${passenger}.${field}`, value)
                      }}
                      index={index}
                      currentSelection={fields.get(index).gender}
                      formFields={fields}
                      name={`${passenger}.${field}`}
                    />
                  </UILayout>
                </UILayout>
              )
            }
            if (field === 'birthday') {
              return (
                <UILayout xs={birthDayWrapStyleXs} row center width="100%" padding="12px 0 14px 0">
                  <UIText padding="10px 0 0 0 " size="18px" color="#79909b" align="left" minWidth="112px">
                    {translate(messages.dateOfBirth)}
                  </UIText>
                  <UILayout
                    data-testid="date-selection-fields"
                    row
                    center
                    width="100%"
                    padding="10px 0 0 9px"
                    justify="space-around"
                  >
                    <DropDownField
                      onChange={(e, value) => {
                        this.props.blur('bookingForm', `${passenger}.birthday.date`, birthDatesOptions[value].index)
                      }}
                      name={`${passenger}.birthday.date`}
                      required
                      hideStar
                      placeholder={'Day'}
                      styles={dateFldStyle}
                      labelContainerProps={labelStyle}
                      inputContainerProps={inputContainerStyle}
                      inputStyles={inputInnerStyle}
                      itemsArr={birthDatesOptions}
                      itemTemplate={CompanyItemTemplate}
                      selectionTemplate={CompanyItemTemplate}
                      errorStyles={errorStyles}
                      xs={dateFldStyleXs}
                    />

                    <DropDownField
                      onChange={(e, value) => {
                        this.props.blur('bookingForm', `${passenger}.birthday.month`, birthMonthsOptions[value].index)
                      }}
                      name={`${passenger}.birthday.month`}
                      required
                      hideStar
                      styles={monthFldStyle}
                      labelContainerProps={labelStyle}
                      inputContainerProps={inputContainerStyle}
                      inputStyles={inputInnerStyle}
                      itemsArr={birthMonthsOptions}
                      itemTemplate={CompanyItemTemplate}
                      selectionTemplate={CompanyItemTemplate}
                      errorStyles={errorStyles}
                      xs={dateFldStyleXs}
                    />
                    <DropDownField
                      label="Year"
                      onChange={(e, value) => {
                        this.props.blur('bookingForm', `${passenger}.birthday.year`, value)
                      }}
                      name={`${passenger}.birthday.year`}
                      required
                      hideStar
                      styles={yearFldStyle}
                      labelContainerProps={labelStyle}
                      inputContainerProps={inputContainerStyle}
                      inputStyles={inputInnerStyle}
                      itemsArr={birthYearsOptions}
                      itemTemplate={CompanyItemTemplate}
                      selectionTemplate={CompanyItemTemplate}
                      errorStyles={errorStyles}
                      xs={yearFldStyleXs}
                    />
                  </UILayout>
                </UILayout>
              )
            }
            if (field === 'citizenship') {
              return (
                <UILayout row center width="100%">
                  <UIText padding="10px 0 0 0 " size="18px" color="#79909b" align="left" minWidth="112px">
                    {translate(messages[field])}
                  </UIText>
                  <UILayout row center width="100%" padding="10px 0 0 9px">
                    <DropDownField
                      onChange={(e, value) => {
                        this.props.blur('bookingForm', `${passenger}.citizenship`, value)
                        this.setInitialList()
                      }}
                      name={`${passenger}.${field}`}
                      required
                      hideStar
                      styles={{ minWidth: '120px', padding: 0, width: '100%' }}
                      labelContainerProps={{ display: 'none' }}
                      inputContainerProps={{ padding: 0 }}
                      inputStyles={{ boxShadow: 'none', borderBottom: '1px solid rgb(191, 195, 199)' }}
                      itemsArr={citizenshipList}
                      fullItemsList={citizenships}
                      itemTemplate={CitizenshipItemTemplate}
                      selectionTemplate={CountryItemTemplate}
                      errorStyles={errorStyles}
                    />
                  </UILayout>
                </UILayout>
              )
            }
            return (
              <UILayout column padding="12px 0 14px 0">
                <InputField
                  key={`${passenger}.${field}`}
                  required={field !== 'optionalInfo'}
                  hideStar
                  name={`${passenger}.${field}`}
                  text={translate(messages[field])}
                  wrapperInputStyles={{ padding: '0' }}
                  wrapperStyles={{ padding: '0', column: false }}
                  errorStyles={errorStyles}
                  style={{ boxShadow: '0 ', borderBottom: '1px solid #bfc3c7' }}
                  wrapperLabelStyles={{ width: 'auto', 'flex-end': true, display: 'flex' }}
                  labelStyles={commentsLabelStyles}
                  {...muiTheme.ids.InputField}
                />
              </UILayout>
            )
          })
        )}
      </React.Fragment>
    )
  }
}

PassengerEditForm.contextTypes = {
  intl: PropTypes.any,
  muiTheme: PropTypes.any,
}

const mapStateToProps = (state) => ({
  citizenships: getCitizenshipList(state),
  // bookingFormValues:
  //   state && state.form && state.form.bookingForm && state.form.bookingForm.values
  //     ? state.form.bookingForm.values
  //     : null,
})

const mapDispatchToProps = {
  blur,
  setReservationPassengerInfo: (index) => setReservationPassengerInfo({ index }),
}

export default connect(mapStateToProps, mapDispatchToProps)(PassengerEditForm)
