import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { UILayout } from 'bora-material-ui'
import MessageBox from '../../Common/components/MessageBox'

import { selectors } from '../provider'

const styles = {
  wrapperXs: { minHeight: 'inherit' },
}

const FormLayout = ({ children, className, additionalStyles, authError, authMessage }) => (
  <UILayout
    className={className}
    column
    center
    id="contentWrapperLight"
    {...additionalStyles}
    minHeight="450px"
    xs={styles.wrapperXs}
  >
    <MessageBox margin="0 0 20px 0" text={authMessage || authError} type={authError ? 'error' : 'info'} />
    {children}
  </UILayout>
)

FormLayout.propTypes = {
  authError: PropTypes.string.isRequired,
  authMessage: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  additionalStyles: PropTypes.any,
}

FormLayout.defaultProps = {
  additionalStyles: '',
}

FormLayout.contextTypes = {
  muiTheme: PropTypes.object,
}

const mapStateToProps = (state) => ({
  authError: selectors.getError(state),
  authMessage: selectors.getMessage(state),
})

export default connect(mapStateToProps)(FormLayout)
