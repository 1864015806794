import R, { compose, filter, propEq, map, prop, flatten, find } from 'ramda'
import { isSaaremaa } from '../../../../utils/saaremaaStyleUtils'

const getTicketCreatorFor = (sailPackage, sailRefId) => (ticketType, itemArgs) => {
  return {
    ...(itemArgs.weightEditAllowed ? ticketType(itemArgs, sailRefId) : ticketType(itemArgs)),
    sailPackage,
    sailRefIds: [sailRefId],
    seqN: Math.round(0.5 + Math.random() * 99999999),
  }
}

const getCodeFromItem = R.compose(R.find(Boolean), R.props(['code', 'priceCategory']))

const isCorrectItem = (item) => {
  const code = getCodeFromItem(item)
  return code && code !== 'LOCAL_TRAVELLER'
}

const getModifiedItemsFrom = R.compose(R.filter(isCorrectItem), R.values)

const getReservationItemsBySeqNFrom = (seqN) =>
  R.compose(R.filter(R.propEq('sailPackageSeqN', seqN)), R.propOr([], 'items'))

const guestReducer = (acc, item) => {
  const [localId, seqN] = R.props(['personalIdentificationNumber', 'seqN'], item)
  const formattedItem = localId ? { [localId]: seqN } : seqN
  return R.concat(acc, [formattedItem])
}

const getGuestsFrom = (nums = []) =>
  compose(
    R.evolve({ residents: R.mergeAll }),
    R.reduceBy(guestReducer, [], (item) => (R.has('personalIdentificationNumber', item) ? 'residents' : 'guests')),
    filter(({ seqN }) => nums.includes(seqN)),
    R.propOr([], 'guests')
  )

const seqNumsForIndex = (spSeqIndex = 1) =>
  compose(flatten, map(prop('ownerSeqNs')), filter(propEq('sailPackageSeqN', spSeqIndex)))

const getLocalIDsFrom = R.compose(R.pluck('localID'), R.propOr([], 'localIDs'))
const getBackUpRegistryUsed = R.compose(R.head, R.pluck('backupRegistryUsed'), R.propOr([], 'localIDs'))

const differenceLocalIDs = (residents, formValues) => {
  const oldLocalIDs = R.keys(residents)
  const localIDs = getLocalIDsFrom(formValues)
  const backUpRegistryUsed = getBackUpRegistryUsed(formValues)

  const localIDsForCreate = R.without(oldLocalIDs, localIDs)
  const localIDsForDelete = R.without(localIDs, oldLocalIDs)
  return { localIDsForCreate, localIDsForDelete, backUpRegistryUsed }
}

const countCodeCounts = R.compose(
  (groupedOriginalItems) => (item) =>
    R.merge(
      { count: R.propOr(0, 'count', item) },
      R.propOr({ originalCount: 0, ownerSeqNs: [] }, getCodeFromItem(item), groupedOriginalItems)
    ),
  R.reduceBy(
    (acc, { quantity: originalCount = 0, ownerSeqNs = [] }) => {
      // console.log(priceCategory, originalCount)
      return {
        originalCount,
        ownerSeqNs,
      }
    },
    {},
    getCodeFromItem
  )
)

const prepareLocalIDsForCreate = (localIDs, item) => {
  const itemIDs = R.propOr([], 'localIDs', item)
  const ids = R.intersection(localIDs, itemIDs)
  const priceCategory = getCodeFromItem(item)
  const backUpRegistryUsed = item.backUpRegistryUsed || false
  return R.map(
    (personalIdentificationNumber) => ({
      passenger: { priceCategory, personalIdentificationNumber, backUpRegistryUsed },
    }),
    ids
  )
}

const passengerType = (item) => {
  const priceCategory = getCodeFromItem(item)
  const { localID: personalIdentificationNumber = '' } = item
  return {
    passenger: { priceCategory, personalIdentificationNumber },
  }
}

const isVehicleType = ({ subType }) => ['VEHICLE', 'BICYCLE', 'TRAILER'].includes(subType)
const isBicycle = R.propEq('inventoryClass', 'BICYCLE')

const vehicleType = (item, sailRefId = '') => {
  const code = getCodeFromItem(item)
  const {
    licensePlate,
    plateNumber,
    handicapped,
    customVehicleParams: {
      heightInCm,
      lengthInCm,
      weightInKg,
      widthInCm,
      companyRegistrationNumber: companyRegistrationNumberManual,
      backupBusinessRegistryUsed,
      backupRoadAdministrationRegistryUsed,
    } = {},
    manuallyChangedWeights = [],
  } = item

  const companyRegistrationNumber = R.path(['companyRegistrationNumber', 'companyRegistrationNumber'], item)

  const vehicle = () => {
    if (isBicycle(item)) {
      return { priceCategory: code }
    }

    if (licensePlate) {
      return {
        licencePlate: licensePlate,
        companyRegistrationNumber,
        handicapped,
        ...(manuallyChangedWeights && { weightOnSails: [{ sailRefId, weightInKg: manuallyChangedWeights[0] }] }),
      }
    }

    return {
      heightInCm,
      lengthInCm,
      weightInKg,
      widthInCm,
      companyRegistrationNumber: companyRegistrationNumberManual,
      backupBusinessRegistryUsed,
      backupRoadAdministrationRegistryUsed,
      licencePlate: plateNumber,
      priceCategory: code,
    }
  }

  return { vehicle: vehicle() }
}

const isPassengerResident = R.converge(R.and, [() => !isSaaremaa, R.propEq('type', 'PASSENGER'), R.prop('resident')])

const prepareForDelete = (codes) =>
  R.compose(
    R.reduceBy(
      (acc, { sailPackageSeqN, ownerSeqNs }) =>
        R.concat(
          acc,
          R.map((seqN) => ({ sailPackageSeqN, seqN }), ownerSeqNs)
        ),
      [],
      ({ type }) => (type === 'PASSENGER' ? 'items' : 'vehicles')
    ),
    R.filter((item) => !codes.includes(getCodeFromItem(item)) && !isPassengerResident(item))
  )

const mapIndexed = R.addIndex(R.map)
const getItemsFromArgs = (() => {
  const fieldNames = ['ticketsRequests', 'vehiclesRequests']
  return mapIndexed((items, index) => !R.isEmpty(items) && { [fieldNames[index]]: [...items] })
})()

const createModification = (__type, ...args) => {
  const [tickets, vehicles] = getItemsFromArgs(args)
  if (tickets || vehicles) {
    return { __type, ...tickets, ...vehicles }
  }
  return false
}

const createModificationWithSaleChannel = (__type, ...args) => {
  const [tickets, vehicles] = getItemsFromArgs(args)
  if (tickets || vehicles) {
    return {
      __type,
      saleChannel: window.brandProps.saleChannel,
      ...tickets,
      ...vehicles,
    }
  }
  return false
}

export const countResultOfChangesToSend = ({
  newData: formValues,
  editReservation = { items: [], guests: [] },
  sailPackage,
  sailRefId,
  seqN: sailPackageSeqN = 1,
}) => {
  const createTicket = getTicketCreatorFor(sailPackage, sailRefId)
  const reservationItems = getReservationItemsBySeqNFrom(sailPackageSeqN)(editReservation)
    .filter(({ promotion }) => !promotion)
    .filter(({ internal }) => !internal)

  const codeCount = countCodeCounts(reservationItems)

  const { items: itemsFromReservation = [] } = editReservation
  const seqNumsForTheLeg = seqNumsForIndex(sailPackageSeqN)(itemsFromReservation)
  const { residents = [], guests = [] } = getGuestsFrom(seqNumsForTheLeg)(editReservation)

  const { localIDsForCreate, localIDsForDelete, backUpRegistryUsed } = differenceLocalIDs(residents, formValues)

  const itemsForDelete = R.map((itemID) => ({ sailPackageSeqN, seqN: residents[itemID] }), localIDsForDelete)

  const regularPassengerItemsToBeDeletedCompletely = itemsFromReservation
    .filter((item) => !(item.priceCategory in formValues))
    .flatMap((item) => item.ownerSeqNs.map((sn) => ({ sailPackageSeqN, seqN: sn })))

  const addedItemsToSend = []
  const deletedItemsToSend = [...itemsForDelete, ...regularPassengerItemsToBeDeletedCompletely]

  const addedVehiclesToSend = []
  const deletedVehiclesToSend = []

  const modifiedItems = getModifiedItemsFrom(formValues)
  for (const item of modifiedItems) {
    const { count, originalCount, ownerSeqNs } = codeCount(item)

    const diffCount = count - originalCount

    const isVehicle = isVehicleType(item)

    if (!isSaaremaa && !isVehicle && R.prop('resident', item)) {
      // TODO: We need to check route Enable residents attribute to disable this check
      let residentItem = item
      if (item.localID) {
        const findAltCategoryByLocalId = compose(prop('priceCategory'), find(propEq('localID', item.localID)))
        const altPriceCategory = findAltCategoryByLocalId(formValues.localIDs || [])

        residentItem = {
          ...item,
          ...(altPriceCategory && { priceCategory: altPriceCategory, code: altPriceCategory, backUpRegistryUsed }),
        }
      }

      const localIDs = prepareLocalIDsForCreate(localIDsForCreate, residentItem)
      const newLocalIDs = R.map((item) => createTicket(R.identity, item), localIDs)
      addedItemsToSend.push(...newLocalIDs)
      continue
    }

    if (diffCount > 0) {
      const ticketType = isVehicle ? vehicleType : passengerType
      const items = Array(diffCount)
        .fill()
        .map(() => createTicket(ticketType, item))
      const acc = isVehicle ? addedVehiclesToSend : addedItemsToSend
      acc.push(...items)
    } else if (diffCount < 0) {
      const seqNs = isVehicle ? ownerSeqNs : R.intersection(ownerSeqNs, guests)
      const seqNsForDelete = seqNs.slice(diffCount)
      const items = seqNsForDelete.map((seqN) => ({ sailPackageSeqN, seqN }))
      const acc = isVehicle ? deletedVehiclesToSend : deletedItemsToSend
      acc.push(...items)
    } else if (isVehicle && !isBicycle(item)) {
      const [seqN] = ownerSeqNs
      deletedVehiclesToSend.push({ sailPackageSeqN, seqN })
      addedVehiclesToSend.push(createTicket(vehicleType, item))
    }
  }

  const { items = [], vehicles = [] } = prepareForDelete(R.keys(formValues))(reservationItems)

  deletedItemsToSend.push(...items)
  deletedVehiclesToSend.push(...vehicles)

  return {
    modifications: [
      createModificationWithSaleChannel('addItems', addedItemsToSend, addedVehiclesToSend),
      createModification('deleteItems', deletedItemsToSend, deletedVehiclesToSend),
    ].filter(Boolean),
  }
}
