/* eslint-disable react/style-prop-object,prefer-destructuring */
import React from 'react'
import { compose, pathOr, head, length } from 'ramda'
import PropTypes from 'prop-types'
import { UILayout, UIDivider } from 'bora-material-ui'

import { SumRow } from './Layout'

import messages from '../../../../../../../consts/messages'

import TripTicket from './TripTicket'

import { EditModeContext } from '../../../../../../MyTicket/containers/MyTicketEditMode'
import TotalPrice from '../TotalPrice'
import { THEME } from 'bora-material-ui/themes'
import { translate } from '../../../../../../Common/Translator'
import { getStyle, isLiinilaevad } from '../../../../../../../utils/liinilaevadThemeUtils'

const xsStyleSum = { width: '100%', padding: '10px 0' }
const xsStyleSumWrapper = window.brandProps.theme === THEME.SAAREMAA ? { style: { 'flex-direction': 'inherit' } } : {}
const xsStyleTotalWrapper = window.brandProps.theme === THEME.SAAREMAA ? { style: { width: '100%', padding: '0' } } : {}
const xsStyleTicketsWrap = { style: { borderBottom: '0' }, column: true }

const getRouteCode = compose(pathOr('', ['sailRefs', 0, 'route']), head)

const styles = {
  borderBottom: { borderBottom: '1px solid #bfc3c7' },
  borderTop: { borderTop: '1px solid #bfc3c7' },
}

class TicketsInfo extends React.PureComponent {
  render() {
    const { intl } = this.context

    const {
      reservation,
      onEdit,
      onDelete,
      trips,
      userNeedToPayAfterEdit,
      isLaaksaare = false,
      outOfBookingBoundary = false,
    } = this.props

    const [tripThere, tripBack] = trips || [{}, {}]
    const { paymentInfo = {}, vehicles = [], sailPackages = [] } = reservation || {}
    const { amount, currency, taxAmount, amountWithoutTaxes } = paymentInfo.totalPrice || {}

    const routeCode = getRouteCode(sailPackages)

    const shouldHideActions = routeCode && routeCode.includes('TAL-AEG-TAL') && length(sailPackages) > 1
    const tax = (window.brandProps.backendConfig && window.brandProps.backendConfig.taxAmount) || 20
    return (
      <EditModeContext.Consumer>
        {(editMode) => (
          <UILayout column>
            <UILayout padding="22px 0 17px 0" style={styles.borderBottom} sm={xsStyleTicketsWrap}>
              <TripTicket
                display-if={tripThere}
                trip={tripThere}
                dataTest="ticket-one"
                onEdit={onEdit}
                width={!tripBack && editMode ? '100%' : '50%'}
                onDelete={onDelete}
                vehicles={vehicles}
                editMode={editMode}
                isTwoWayTrip={tripBack}
                userNeedToPayAfterEdit={userNeedToPayAfterEdit}
                shouldHideActions={shouldHideActions}
              />
              <UIDivider
                display-if={tripBack}
                vertical
                width="1px"
                margin="0 24px"
                height="inherit"
                bgColor="#bfc3c7"
                shrink="0"
                visible
              />
              <TripTicket
                display-if={tripBack}
                trip={tripBack}
                dataTest="ticket-two"
                onEdit={onEdit}
                onDelete={onDelete}
                vehicles={vehicles}
                editMode={editMode}
                isTwoWayTrip={tripBack}
                userNeedToPayAfterEdit={userNeedToPayAfterEdit}
                shouldHideActions={shouldHideActions}
              />
            </UILayout>
            <UILayout
              data-testid="total-sum"
              j-flex-start={!tripBack}
              j-flex-end={getStyle(false, tripBack)}
              display-if={!editMode}
              smm={xsStyleSumWrapper}
            >
              <UILayout
                column
                width={window.brandProps.theme === THEME.SAAREMAA ? '51%' : '50%'}
                padding={getStyle('22px 40px 0 0', `0 22px 0 ${tripBack ? '22px' : '0px'}`)}
                sm={xsStyleSum}
              >
                <SumRow
                  label={translate(messages.subTotalWithTax, { tax })}
                  sum={amountWithoutTaxes}
                  currencyCode={currency}
                />
                <SumRow label={translate(messages.tax, { tax })} sum={taxAmount} currencyCode={currency} />
                <SumRow
                  subTotal={true}
                  label={intl.formatMessage(messages.subTotal)}
                  sum={amount}
                  currencyCode={currency}
                />
              </UILayout>
              <UILayout
                width="50%"
                row
                padding="20px 25px 15px 25px"
                display-if={!editMode && (window.brandProps.theme === THEME.SAAREMAA || isLiinilaevad)}
                smm={xsStyleTotalWrapper}
              >
                <TotalPrice isConfirmPage />
              </UILayout>
            </UILayout>

            <UILayout display-if={isLaaksaare && !outOfBookingBoundary} column width="50%" sm={xsStyleSum}>
              <UILayout style={styles.borderTop} padding="11px 22px 11px 0">
                <TotalPrice reservationFee={true} />
              </UILayout>
            </UILayout>
          </UILayout>
        )}
      </EditModeContext.Consumer>
    )
  }
}

TicketsInfo.propTypes = {
  reservation: PropTypes.object.isRequired,
  trips: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  userNeedToPayAfterEdit: PropTypes.bool,
}

TicketsInfo.defaultProps = {
  userNeedToPayAfterEdit: false,
}

TicketsInfo.contextTypes = {
  intl: PropTypes.any,
}

export default TicketsInfo
