import { mergeDeepRight } from 'ramda'
import UIElements from '../../../consts/UIElements'

const THEMES_STYLES = {
  KIHNU: {
    style: { boxShadow: 'none' },
    inputStyle: { color: 'black' },
    hintStyle: { color: 'black' },
    labelStyles: { color: 'black' },
    underlineStyle: { borderColor: 'black' },
  },
  SEAJETS: {
    style: { boxShadow: 'none' },
    inputStyle: { color: 'black' },
    hintStyle: { color: 'black' },
    labelStyles: { color: 'black' },
    underlineStyle: { borderColor: 'black' },
  },
  POSIDONIA: {
    style: { boxShadow: 'none' },
    inputStyle: { color: 'black' },
    hintStyle: { color: 'black' },
    labelStyles: { color: 'black' },
    underlineStyle: { borderColor: 'black' },
  },
}

const defaultFieldStyles = {
  style: { backgroundColor: 'transparent' },
  wrapperStyles: { padding: '25px 0px 0px 0px' },
  inputStyle: { color: '#000', padding: '0px' },
  hintStyle: { color: '#ffffff', padding: '0px' },
  errorStyles: { margin: '6px 0 0 0' },
  labelStyles: { color: '#ffffff', textTransform: 'uppercase' },
  underlineShow: true,
  type: 'text',
}

const defaultCompanyFieldStyles = {
  style: { backgroundColor: 'transparent', padding: '0' },
  wrapperStyles: { padding: '40px 0px 0px 0px' },
  inputStyle: { color: '#ffffff', padding: '0px' },
  hintStyle: { color: 'rgb(111, 131, 149)', padding: '0px' },
  errorStyles: { margin: '6px 0 0 0' },
  labelStyles: { color: '#ffffff', textTransform: 'uppercase', size: '14px' },
  underlineShow: true,
  wrapperInputStyles: { padding: '0' },
  type: 'text',
}

export const fieldStyles = mergeDeepRight(defaultFieldStyles, THEMES_STYLES[UIElements.APP_NAME] || {})
export const companyFieldStyles = mergeDeepRight(defaultCompanyFieldStyles, THEMES_STYLES[UIElements.APP_NAME] || {})
