import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { change, getFormValues } from 'redux-form'
import { isEmpty, map, pathOr, toUpper, values } from 'ramda'

import { UIFormattedNumber, UILayout, UIText } from 'bora-material-ui'

import MessageBox from '../../../../Common/components/MessageBox'
import {
  FormLayout,
  VehicleCompanyRegNumberParam,
  VehicleConfirmButton,
  VehicleParam,
  VehicleTicket,
} from '../components'
import { selectors } from '../../../provider'
import { TicketTypeRow } from '../../TicketsForm/components'
import {
  getIsRequestingCalcPrices,
  getVehicleCategoriesListWithPrices,
} from '../../../../../services/pricing/reducers/selectors'
import { calculatePrice } from '../../../../../services/pricing/actions'

import * as VEHICLE_PARAMS from '../customVehicleParams'
import { DropDownField, IncDecField, OptionsRadioGroup, PromoCodeField } from '../../../../../formComponents'

import messages from '../../../../../consts/messages'
import { onlyAlphaNumericDash, plateNumberRegexp } from '../../../../../utils/validate'
import {
  getVehiclePlateNumbers,
  isCurrentResLaaksaareRoute,
  isEditResLaaksaareRoute,
} from '../../../../../services/reservation/selectors'
import { isLaaksaareInSelectedSails } from '../../../../../services/schedule/reducers'
import { filterItemsByType } from './SimplifiedForm'
import { getLastRequest, getNetworkErrorResult } from '../../../../../reducers/network'

const paramsForFormType = {
  vehiclesForm: {
    icon: 'iconCar',
    dropdownDescription: 'Vehicle ticket',
    categoriesSelector: getVehicleCategoriesListWithPrices,
    reservationVehicleSelector: selectors.getFirstVehicleItem,
  },
  trailersForm: {
    icon: 'trailer',
    dropdownDescription: 'Trailer ticket',
    categoriesSelector: selectors.getTrailerCategoriesListWithPrices,
    reservationVehicleSelector: selectors.getFirstTrailerItem,
  },
}

const styles = {
  padding0: { padding: '0' },
  column: { column: true },
}

// eslint-disable-next-line complexity,max-lines-per-function
const InputManually = (
  {
    vehiclesFormValues,
    changeField,
    categories,
    confirm,
    editMode,
    currency,
    formName,
    calculatePrice,
    selectedSails,
    reservationVehicle,
    routeCoefficientsMap,
    locale,
    changeReduxFormField,
    isLaaksaare,
    vehicleItems,
    trailerItems,
    editDataRequestAllocateError = false,
    vehicleNumbers = [],
    shouldReplaceVehicleWidth = false,
    isManualVehicleFormShownExplicitly = false,
    requestingCalcPrices,
  },
  { intl = false, muiTheme }
) => {
  const {
    selectedManuallyVehicle,
    plateNumberManuallyVehicle,
    vehicleManuallyTicket,
    customVehicleParams = VEHICLE_PARAMS.default,
    plateNumber,
    calculatedTicketObj,
    companyRegistrationNumber,
  } = vehiclesFormValues
  const { widthInCm, heightInCm, lengthInCm, weightInKg } = customVehicleParams || {}
  const chooseVehicleParam = (param, value) => {
    changeField('customVehicleParams', { ...customVehicleParams, [param]: value })
  }

  let vehicleNotAddedSuccessfully = formName === 'vehiclesForm' ? isEmpty(vehicleItems) : isEmpty(trailerItems)
  if (editMode) vehicleNotAddedSuccessfully = false

  if (formName !== 'vehiclesForm') {
    if (editDataRequestAllocateError) {
      if (isEmpty(trailerItems)) vehicleNotAddedSuccessfully = true
      else {
        vehicleNotAddedSuccessfully = !vehicleNumbers.includes(plateNumber)
      }
    }
  } else {
    // eslint-disable-next-line no-lonely-if
    if (editDataRequestAllocateError) {
      if (isEmpty(vehicleItems)) vehicleNotAddedSuccessfully = true
      else {
        vehicleNotAddedSuccessfully = !vehicleNumbers.includes(plateNumber)
      }
    }
  }

  const vehicleAddedSuccessfully = !vehicleNotAddedSuccessfully

  const calculatedPrice = pathOr('', ['price'], calculatedTicketObj)
  const calculatedCoefficients = pathOr([], ['coefficients'], calculatedTicketObj)

  const coefficientsText = [
    `${routeCoefficientsMap.header}\n`,
    ...map((code) => {
      if (code.includes('SOR-TRI-SOR_WIDTH-COEF') && !shouldReplaceVehicleWidth) {
        return routeCoefficientsMap[`${code}_${toUpper(locale)}_BEFORE_01.03.2022`]
      }
      return routeCoefficientsMap[`${code}_${toUpper(locale)}`]
    }, calculatedCoefficients),
  ].join('\n')

  const [sailRefId] = values(selectedSails)

  const vehicleForManualForm = editMode && selectedManuallyVehicle

  const vehObject = {
    ...customVehicleParams,
    formName,
    priceCategory: pathOr('', ['code'], selectedManuallyVehicle),
    plateNumber,
    sailRefId,
    companyRegistrationNumber,
    backupRoadAdministrationRegistryUsed: isManualVehicleFormShownExplicitly,
  }

  const companyStatus = pathOr('', ['status'], companyRegistrationNumber)
  // const errorCode = pathOr('', ['code'], selectedVehicle)

  const getPriceValue = () =>
    calculatedPrice ||
    String(pathOr('', ['price'], selectedManuallyVehicle)) ||
    (plateNumberManuallyVehicle || {}).price
  return (
    <FormLayout
      additionalStyles={{ margin: '10px 0 15px 0', bgColor: editMode ? 'transparent' : '#e5e5e5' }}
      editMode={editMode}
    >
      <UILayout column={editMode} sm={styles.column}>
        <UILayout
          column
          padding={!editMode ? '0 30px 0 0' : '0'}
          childrenStyle={{ margin: !editMode ? '0 0 30px 0' : '0 0 16px 0' }}
          sm={styles.padding0}
        >
          <DropDownField
            onlySelect
            itemsArr={categories}
            disabled={vehicleManuallyTicket}
            inputContainerProps={styles.padding0}
            styles={{ padding: '0', width: '100%' }}
            label={intl.formatMessage(messages.passengerTicket)}
            select={(value) => {
              calculatePrice({ ...vehObject, priceCategory: value.code })
              changeField('selectedManuallyVehicle', {
                ...value,
                descr: paramsForFormType[formName].dropdownDescription,
              })
            }}
            itemTemplate={({ value }) => (
              <UILayout data-testid="vehicle-category">
                <TicketTypeRow
                  hideZeroPrice={isLaaksaare}
                  title={value.title}
                  price={value.price}
                  currencyCode={value.currency || 'eur'}
                />
              </UILayout>
            )}
            selectionTemplate={() =>
              selectedManuallyVehicle ? (
                <TicketTypeRow title={selectedManuallyVehicle.title} isSection />
              ) : (
                <UILayout data-testid="vehicle-category-drop-down">
                  <TicketTypeRow
                    title={intl.formatMessage(messages.chooseVehicleType)}
                    icon={paramsForFormType[formName].icon}
                    isSection
                  />
                </UILayout>
              )
            }
          />
          <UILayout>
            <OptionsRadioGroup
              name="widthInCm"
              label={`${intl.formatMessage(messages.width)}:`}
              options={shouldReplaceVehicleWidth ? VEHICLE_PARAMS.WIDTH_BUTTONS_SVI : VEHICLE_PARAMS.WIDTH_BUTTONS}
              activeValue={widthInCm}
              onChange={(value) => {
                chooseVehicleParam('widthInCm', value)
                calculatePrice({ ...vehObject, widthInCm: value })
              }}
              disabled={Boolean(vehicleManuallyTicket)}
              testId="width-in-cm-radio"
            />
            <OptionsRadioGroup
              name="heightInCm"
              label={`${intl.formatMessage(messages.height)}:`}
              options={VEHICLE_PARAMS.HEIGHT_BUTTONS}
              activeValue={heightInCm}
              onChange={(value) => {
                chooseVehicleParam('heightInCm', value)
                calculatePrice({ ...vehObject, heightInCm: value })
              }}
              disabled={Boolean(vehicleManuallyTicket)}
              testId="height-in-cm-radio"
            />
          </UILayout>
          <UILayout
            data-testid="vehicle-estimate-price-params"
            column
            center
            bgColor="white"
            padding="13px 15px"
            display-if={(selectedManuallyVehicle || plateNumberManuallyVehicle) && !vehicleManuallyTicket}
            boxShadow="0 24px 24px 0 rgba(0, 0, 0, 0.06), 0 0 24px 0 rgba(0, 0, 0, 0.22)"
          >
            <VehicleParam
              testId="vparam-lengthInCm"
              label={intl.formatMessage(messages.length)}
              value={`${lengthInCm}cm`}
            />
            <VehicleParam
              testId="vparam-widthInCm"
              label={intl.formatMessage(messages.width)}
              value={`${widthInCm}cm`}
            />
            <VehicleParam
              testId="vparam-heightInCm"
              label={intl.formatMessage(messages.height)}
              value={`${heightInCm}cm`}
            />
            <VehicleParam
              testId="vparam-weightInKg"
              label={intl.formatMessage(messages.weight)}
              value={`${weightInKg}kg`}
            />
            <UILayout
              display-if={!isLaaksaare}
              j-flex-space-between
              borderTop="solid 1px #bfc3c7"
              padding="13px 0 0 0"
              margin="13px 0px 0px 0px"
            >
              <UIText color="#79909b" size="18px">
                {intl.formatMessage(messages.vehicleEstimatedPrice)}:
              </UIText>
              <UIText color="#79909b" size="18px" data-testid="vehicle-estimate-price-value">
                <UIFormattedNumber
                  value={getPriceValue()}
                  style="currency"
                  currency={currency || muiTheme.defaultCurrency || 'EUR'}
                />
              </UIText>
            </UILayout>
          </UILayout>
          <VehicleTicket
            onClose={() => {
              const { selectedManuallyVehicle: vehicleTicket, customVehicleParams } = vehiclesFormValues
              vehicleTicket.customVehicleParams = { ...customVehicleParams }
              confirm({
                ...vehicleTicket,
                plateNumber,
                count: 0,
                type: 'vehicle-close-icon',
                formType: formName,
              })
              changeReduxFormField('ticketsForm', `${formName.substring(0, 7)}IsActive`, false)
              changeField('formMayBeClosed', true)
            }}
            hideZeroPrice={isLaaksaare}
            showCloseButton={reservationVehicle}
            display-if={reservationVehicle}
            price={reservationVehicle.price}
            description={reservationVehicle.priceCategoryTranslation}
            currency={currency}
          />
        </UILayout>
        <UILayout column childrenStyle={{ margin: !editMode ? '0 0 30px 0' : '0 0 16px 0' }}>
          <PromoCodeField
            name="plateNumber"
            disabled={vehicleManuallyTicket}
            data-testid="plate-number"
            placeholder={intl.formatMessage(messages.licensePlateNumber)}
            normalize={onlyAlphaNumericDash}
            onChange={(e, string) => {
              if (plateNumberRegexp.test(string)) {
                const plateNumberString = string.toUpperCase()
                changeField('plateNumber', plateNumberString)
              } else if (plateNumberManuallyVehicle) {
                changeField('plateNumber', null)
                if (vehicleManuallyTicket) changeField('vehicleManuallyTicket', null)
              } else {
                changeField('plateNumber', null)
              }
            }}
            onBlur={(e, value) => calculatePrice({ ...vehObject, plateNumber: value })}
            wrapperStyles={styles.padding0}
            inputWrapperStyles={styles.padding0}
            {...muiTheme.ids.carCode}
          />
          <UILayout>
            <IncDecField
              name="lengthInCm"
              label={`${intl.formatMessage(messages.length)} (m):`}
              range={VEHICLE_PARAMS.LENGTH_RANGE}
              activeValue={lengthInCm}
              onChange={(value) => chooseVehicleParam('lengthInCm', value)}
              onBlur={(value) => calculatePrice({ ...vehObject, lengthInCm: value })}
              unitCoefficient={100}
              disabled={Boolean(vehicleManuallyTicket) && vehicleAddedSuccessfully}
            />
            <IncDecField
              name="weightInKg"
              label={`${intl.formatMessage(messages.weight)} (t):`}
              range={VEHICLE_PARAMS.WEIGHT_RANGE}
              activeValue={weightInKg}
              onChange={(value) => chooseVehicleParam('weightInKg', value)}
              onBlur={(value) => calculatePrice({ ...vehObject, weightInKg: value })}
              unitCoefficient={1000}
              disabled={Boolean(vehicleManuallyTicket) && vehicleAddedSuccessfully}
            />
          </UILayout>
          <VehicleCompanyRegNumberParam
            display-if={
              isManualVehicleFormShownExplicitly && (selectedManuallyVehicle || plateNumberManuallyVehicle || editMode)
            }
            value={companyRegistrationNumber && companyRegistrationNumber.value}
            onClick={(value) => {
              chooseVehicleParam('companyRegistrationNumber', value)
              calculatePrice({ ...vehObject, companyRegistrationNumber: value })
            }}
            disabled={requestingCalcPrices}
            errorCode={companyStatus === 'failed'}
          />
          <VehicleTicket
            onClose={() => {
              const { selectedManuallyVehicle: vehicleTicket, customVehicleParams } = vehiclesFormValues
              vehicleTicket.customVehicleParams = { ...customVehicleParams }
              confirm({
                ...vehicleTicket,
                plateNumber,
                count: 0,
                type: 'vehicle-close-icon',
                formType: formName,
              })
              changeReduxFormField('ticketsForm', `${formName.substring(0, 7)}IsActive`, false)
              changeField('formMayBeClosed', true)
            }}
            showCloseButton={vehicleForManualForm}
            display-if={vehicleManuallyTicket && vehicleForManualForm}
            price={vehicleForManualForm.price}
            description={vehicleForManualForm.title}
            iconType={formName === 'vehiclesForm' ? 'iconCar' : 'trailer'}
            currency={currency}
            hideZeroPrice={isLaaksaare}
          />
          <VehicleConfirmButton
            display-if={selectedManuallyVehicle || plateNumberManuallyVehicle}
            onClick={() => {
              changeField('vehicleManuallyTicket', selectedManuallyVehicle || plateNumberManuallyVehicle)
              const { selectedManuallyVehicle: vehicleTicket } = vehiclesFormValues

              customVehicleParams.backupRoadAdministrationRegistryUsed = isManualVehicleFormShownExplicitly

              if (isManualVehicleFormShownExplicitly && customVehicleParams.companyRegistrationNumber) {
                customVehicleParams.backupBusinessRegistryUsed = true
              }
              confirm({
                ...vehicleTicket,
                plateNumber,
                count: 1,
                type: 'confirm',
                customVehicleParams,
              })
            }}
            disabledMessage={vehicleManuallyTicket ? messages.dataForVehicleWasAdded : messages.confirmDataForVehicle}
            enabled={!requestingCalcPrices && (vehicleNotAddedSuccessfully || (!vehicleManuallyTicket && plateNumber))}
          />
          <UILayout column display-if={!isEmpty(calculatedCoefficients)}>
            <MessageBox margin="0 0 0 0" text={coefficientsText} type="error" />
          </UILayout>
        </UILayout>
      </UILayout>
    </FormLayout>
  )
}

InputManually.propTypes = {
  changeField: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  vehiclesFormValues: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  editMode: PropTypes.bool,
  isLaaksaare: PropTypes.bool,
  formName: PropTypes.string,
  specialPackageMode: PropTypes.bool,
  vehicleItems: PropTypes.array,
  trailerItems: PropTypes.array,
  editDataRequestAllocateError: PropTypes.bool,
  vehicleNumbers: PropTypes.array,
  shouldReplaceVehicleWidth: PropTypes.bool,
  isManualVehicleFormShownExplicitly: PropTypes.bool,
  requestingCalcPrices: PropTypes.bool,
}

InputManually.defaultProps = {
  editMode: false,
  isLaaksaare: false,
  formName: 'vehiclesForm',
  specialPackageMode: false,
  vehicleItems: [],
  trailerItems: [],
  editDataRequestAllocateError: false,
  vehicleNumbers: [],
  isManualVehicleFormShownExplicitly: false,
  requestingCalcPrices: false,
}

InputManually.contextTypes = {
  intl: PropTypes.any,
  muiTheme: PropTypes.object,
}

const mapStateToProps = (state, { formName, editMode }) => {
  const currentReservation = selectors.getCurrentReservation(state)
  const isLaaksaare = editMode
    ? isEditResLaaksaareRoute(state)
    : isLaaksaareInSelectedSails(state) || isCurrentResLaaksaareRoute(state)
  const selectedRoute = pathOr('', ['userSelections', 'sailPackages', [0], 'route'])(state)

  const lastReqError = getNetworkErrorResult(state) || ''
  const lastRequest = getLastRequest(state)
  const editDataRequestAllocateError = editMode
    ? lastRequest.endsWith('editData') && lastReqError.startsWith('FAILED_TO_ALLOCATE')
    : false

  const { code = '' } = selectors.getFirstSelectedSailPackage(state)
  const requestingCalcPrices = getIsRequestingCalcPrices(state)

  // const outOfBookingBoundary = getBookingConditionsForRoute(state)
  const sailDate = selectors.getSailDate(state)
  const tripDate = new Date(sailDate)
  const shouldReplaceVehicleWidth = !(code.includes('TRI') && tripDate < Number(new Date('2022/03/01')))

  return {
    currency: selectors.getCurrency(state),
    categories: paramsForFormType[formName].categoriesSelector(state),
    vehiclesFormValues: getFormValues(formName)(state) || {},
    selectedSails: state.schedule.selectedSails,
    reservationVehicle: editMode
      ? undefined
      : paramsForFormType[formName].reservationVehicleSelector(currentReservation),
    routeCoefficientsMap: selectors.getRouteCoefficientsMap(selectedRoute)(state),
    locale: selectors.getLocale()(state),
    isLaaksaare,
    vehicleItems: filterItemsByType('VEHICLE')(selectors.getItems(state)),
    trailerItems: filterItemsByType('TRAILER')(selectors.getItems(state)),
    editDataRequestAllocateError,
    vehicleNumbers: getVehiclePlateNumbers(state),
    shouldReplaceVehicleWidth,
    requestingCalcPrices,
  }
}

export default connect(mapStateToProps, { calculatePrice, changeReduxFormField: change })(InputManually)
