import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { isValid, reduxForm } from 'redux-form'
import { UILayout, UIText } from 'bora-material-ui'
import { SignInButton } from '../components/Buttons'
import InputFieldWithIcon from '../components/Fields/InputFieldWithIcon'
import { translate } from '../../Common/Translator'
import messages from '../../../consts/messages'
import { actions, selectors } from '../provider'
import { getParameterByName } from '../../../utils/strings'
import FormLayout from '../components/FormLayout'
import cn from 'classnames'
import style from './Login.module.scss'
import Content from '../../../layout/Content'
import TokenChecker from '../../../TokenChecker'

const styles = {
  wrapperStyles: { margin: '0' },
  width100MinHeight566: { width: '100%', minHeight: '566px' },
}

const xsStyles = {
  padding20: { padding: '20px' },
  margin0WidthAuto: { margin: '0', width: 'auto' },
}

class NewPassword extends React.Component {
  state = { isSendButtonClicked: false, showingMessage: false }

  handleRecovery = ({ recoveryMail, signPasswordRecovery, signSecondPasswordRecovery }) => {
    const resetToken = getParameterByName('hash')
    if (resetToken) {
      const requestBody = {
        token: resetToken,
        newPassword: signPasswordRecovery,
        confirmedNewPassword: signSecondPasswordRecovery,
      }
      this.props.resetPassword(requestBody)
    } else {
      this.props.recover({
        userName: recoveryMail,
        successMessage: translate(messages.recoveryLinkSent),
      })
    }
    this.setState({ isSendButtonClicked: true })
  }

  static getDerivedStateFromProps(props) {
    const { authError, authMessage } = props
    if (authError || authMessage) {
      return { isSendButtonClicked: false, showingMessage: true }
    } else if (!authError || !authMessage) {
      return { showingMessage: false }
    }
    return null
  }

  render() {
    const { isRecoveryAvailable, handleSubmit } = this.props
    const { isSendButtonClicked = false, showingMessage } = this.state
    const resetToken = getParameterByName('hash')

    return (
      <Content id="newPasswordWrap" header={translate(messages.changePassword)}>
        <FormLayout additionalStyles={{ ...xsStyles.width100, width: '100%' }}>
          <TokenChecker enabled>
            <UILayout column center width="100%" smm={xsStyles.padding20}>
              <div className={cn(style.headingWrapper)}>
                <UILayout column>
                  {!resetToken && (
                    <InputFieldWithIcon
                      required
                      icon={'iconEmail'} // 'iconFieldEmail'
                      name="recoveryMail"
                      additionalWrapperStyles={styles.wrapperStyles}
                    />
                  )}
                  {resetToken && (
                    <Fragment>
                      <UIText size="18px" color="#000000" translate={messages.newPassword} />
                      <InputFieldWithIcon required name="signPasswordRecovery" icon="iconPassword" type="password" />
                      <InputFieldWithIcon
                        required
                        name="signSecondPasswordRecovery"
                        icon="iconPassword"
                        type="password"
                      />
                    </Fragment>
                  )}
                  <SignInButton
                    translate={messages.send}
                    margin="32px 0px 0px 0px"
                    disabled={
                      (isSendButtonClicked && !showingMessage) ||
                      (!isSendButtonClicked && showingMessage) ||
                      !isRecoveryAvailable
                    }
                    onClick={handleSubmit(this.handleRecovery)}
                    height="64px"
                    size="18px"
                  />
                </UILayout>
              </div>
            </UILayout>
          </TokenChecker>
        </FormLayout>
      </Content>
    )
  }
}

const isRecoveryFormValid = isValid('recoveryForm')

export default connect(
  (state) => ({
    isRecoveryAvailable: isRecoveryFormValid(state),
    authError: selectors.getError(state),
    authMessage: selectors.getMessage(state),
  }),
  actions
)(reduxForm({ form: 'recoveryForm' })(NewPassword))
