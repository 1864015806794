import React from 'react'
import PropTypes from 'prop-types'

import { prop, isNil, compose, not } from 'ramda'
import { UILayout, UIText, UIIcon } from 'bora-material-ui'
import { THEME } from 'bora-material-ui/themes'
import { getStyle } from '../../../../../utils/liinilaevadThemeUtils'
import { isGavdos } from '../../../../../utils/gavdosStyleUtils'

const isNotNull = compose(not, isNil)

const xsStyles = {
  time: { width: '70px', padding: '0 1rem 0 5px !important' },
  width75: { width: '75px' },
  width85: { width: '85px' },
  size16: { size: '16px' },
}

class TicketRowHeader extends React.PureComponent {
  // eslint-disable-next-line complexity
  render() {
    const { muiTheme } = this.context
    const { availability, hasOnlySeatsAndBikes, hasOnlySeatsAndCars, hasOnlyPassengers = false } = this.props

    const passengerAvailability = prop('Passenger', availability)
    const bikeAvailability = prop('Bike', availability)
    const carAvailability = prop('Car', availability)
    const totalReserves = prop('totalReserves', availability)
    return (
      <UILayout display-if={!isGavdos} width="100%" height="30px" j-flex-space-between data-testid="ticket-row-header">
        <UILayout
          data-testid="clock-icon"
          column
          flex-start
          j-flex-center
          width="210px"
          padding={'0 1.5rem'}
          shrink={0}
          xs={
            hasOnlySeatsAndBikes || hasOnlyPassengers || hasOnlySeatsAndCars
              ? { ...xsStyles.time, ...xsStyles.width85 }
              : xsStyles.time
          }
          style={{ ...muiTheme.ids.ticketRow, ...muiTheme.ids.timePosition }}
        >
          <UIIcon
            type="iconTime"
            fill={getStyle(
              '#0000D5',
              muiTheme.ids.tripHeaderDoubleRow && muiTheme.ids.tripHeaderDoubleRow.separator
                ? muiTheme.ids.tripHeaderDoubleRow.separator.color
                : '#79909B'
            )}
            margin={getStyle('0 14px 0 0')}
            minWidth="33px"
            height="20px"
            xs={{ margin: 0 }}
          />
        </UILayout>

        <UILayout column center j-flex-center width="110px" xs={xsStyles.width75} style={{ ...muiTheme.ids.ticketRow }}>
          <UILayout
            row
            center
            j-flex-start
            width="100%"
            style={{
              ...muiTheme.ids.ticketRow,
              ...muiTheme.ids.passengerPosition,
              paddingLeft: muiTheme.name === THEME.KIHNU ? '7px' : '0',
            }}
            data-testid="passenger-icon-header"
          >
            <UIIcon
              type={muiTheme.iconPassenger || 'iconPassenger'}
              width="16px"
              height="30px"
              margin={getStyle('0 0 0 14px')}
              fill={getStyle(
                '#0000D5',
                muiTheme.ids.tripHeaderDoubleRow && muiTheme.ids.tripHeaderDoubleRow.separator
                  ? muiTheme.ids.tripHeaderDoubleRow.separator.color
                  : ''
              )}
              xs={{ margin: 0 }}
            />
            <UIText
              display-if={
                muiTheme.name !== THEME.SAAREMAA &&
                muiTheme.name !== THEME.KIHNU &&
                muiTheme.name !== THEME.GAVDOS &&
                muiTheme.name !== THEME.LIINILAEVAD
              }
              size="18px"
              lineHeight="1"
              align="left"
              color="#9b9b9b"
              margin="0 0 0 4px"
              font={muiTheme.secondFontFamily}
              xs={xsStyles.size16}
            >
              {`${passengerAvailability || 0}${window.isNaN(totalReserves) ? '' : `(${totalReserves})`}`}
            </UIText>
          </UILayout>
        </UILayout>

        <UILayout
          display-if={
            !muiTheme.disableBicycles && isNotNull(bikeAvailability) && !hasOnlyPassengers && !hasOnlySeatsAndCars
          }
          column
          center
          j-flex-center
          width="110px"
          xs={xsStyles.width75}
          style={{ ...muiTheme.ids.ticketRow }}
          {...(hasOnlySeatsAndBikes && { style: { marginRight: '20%' } })}
        >
          <UILayout row center j-flex-start width="100%" data-testid="bicycle-icon-header">
            <UIIcon
              type={muiTheme.iconBicycle || 'iconBike'}
              width="35px"
              height="22px"
              fill={getStyle(
                '#0000D5',
                muiTheme.ids.tripHeaderDoubleRow && muiTheme.ids.tripHeaderDoubleRow.separator
                  ? muiTheme.ids.tripHeaderDoubleRow.separator.color
                  : ''
              )}
            />
            <UIText
              display-if={
                muiTheme.name !== THEME.SAAREMAA && muiTheme.name !== THEME.KIHNU && muiTheme.name !== THEME.LIINILAEVAD
              }
              size="18px"
              lineHeight="1"
              align="left"
              color="#9b9b9b"
              margin="0 0 0 4px"
              font={muiTheme.secondFontFamily}
              xs={xsStyles.size16}
            >
              {bikeAvailability || 0}
            </UIText>
          </UILayout>
        </UILayout>

        <UILayout
          display-if={
            (isNotNull(carAvailability) && !hasOnlyPassengers && hasOnlySeatsAndCars) ||
            (isNotNull(carAvailability) && !muiTheme.disableCars && !hasOnlySeatsAndBikes && !hasOnlyPassengers)
          }
          column
          center
          j-flex-center
          width="110px"
          xs={xsStyles.width85}
          style={{ ...muiTheme.ids.ticketRow }}
          {...(hasOnlySeatsAndCars && { style: { marginRight: '20%' } })}
        >
          <UILayout row center j-flex-start width="100%" data-testid="vehicle-icon-header">
            <UIIcon
              type={muiTheme.iconVehicle || 'iconCar'}
              width="36px"
              height="22px"
              fill={
                muiTheme.ids.tripHeaderDoubleRow && muiTheme.ids.tripHeaderDoubleRow.separator
                  ? muiTheme.ids.tripHeaderDoubleRow.separator.color
                  : ''
              }
            />
            <UIText
              display-if={
                muiTheme.name !== THEME.SAAREMAA && muiTheme.name !== THEME.KIHNU && muiTheme.name !== THEME.LIINILAEVAD
              }
              size="18px"
              lineHeight="1"
              align="left"
              color="#9b9b9b"
              margin="0 0 0 4px"
              font={muiTheme.secondFontFamily}
              xs={xsStyles.size16}
            >
              {carAvailability || 0}
            </UIText>
          </UILayout>
        </UILayout>

        {/* Empty column space for selected tick */}
        <UILayout
          j-flex-center
          width="40px"
          xs={{ width: '35px' }}
          {...(muiTheme.name !== THEME.SAAREMAA &&
            ((!hasOnlyPassengers &&
              !hasOnlySeatsAndCars &&
              !hasOnlySeatsAndBikes &&
              !isNotNull(carAvailability) &&
              !isNotNull(bikeAvailability)) ||
              hasOnlyPassengers) && { style: { marginRight: '41%' } })}
        />
      </UILayout>
    )
  }
}

TicketRowHeader.contextTypes = {
  muiTheme: PropTypes.object,
}

TicketRowHeader.propTypes = {
  availability: PropTypes.array.isRequired,
  hasOnlySeatsAndBikes: PropTypes.bool,
  hasOnlyPassengers: PropTypes.bool,
  hasOnlySeatsAndCars: PropTypes.bool,
}

export default TicketRowHeader
