import React from 'react'
import PropTypes from 'prop-types'

import { InputField } from '../Fields'

const fieldStyles = { height: '44px', boxShadow: 'none' }
const hintStyle = { color: '#bfc3c7', paddingLeft: '0' }

const InputFieldWithIcon = (props) => {
  const { additionalWrapperInputStyles, additionalWrapperStyles, disabled } = props

  const wrapperInputStyles = {
    height: 'auto',
    bgColor: 'white',
    borderRadius: '4px',
    boxShadow: '0 24px 24px 0 rgba(0, 0, 0, 0.06), 0 0 24px 0 rgba(0, 0, 0, 0.22)',
    padding: '0 16px',
    position: 'static',
    margin: '0 10px 0 0',
    ...additionalWrapperInputStyles,
  }
  const wrapperStyles = {
    position: 'relative',
    padding: '16px 0 0 0',
    margin: '0 10px',
    ...additionalWrapperStyles,
  }

  return (
    <InputField
      {...props}
      key={props.name}
      wrapperInputStyles={wrapperInputStyles}
      fieldStyles={fieldStyles}
      hintStyle={hintStyle}
      hideLabel
      hideStar
      underlineShow={false}
      wrapperStyles={wrapperStyles}
      disabled={disabled}
    />
  )
}

InputFieldWithIcon.propTypes = {
  name: PropTypes.any.isRequired,
  icon: PropTypes.any,
  type: PropTypes.string,
  required: PropTypes.bool,
  additionalWrapperInputStyles: PropTypes.any,
  additionalWrapperStyles: PropTypes.any,
  disabled: PropTypes.bool,
}

InputFieldWithIcon.defaultProps = {
  icon: false,
  type: 'text',
  required: false,
  additionalWrapperInputStyles: {},
  additionalWrapperStyles: {},
}

export default InputFieldWithIcon
