import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import ReservationMessage from './ReservationMessage'

import { translate } from '../../Common/Translator'
import messages from '../../../consts/messages'
import { any, compose, equals, map, pathOr, prop } from 'ramda'

const getReservationAllowanceProp = (prop) => pathOr(true, ['allowance', prop])

const anySailWhichCannotCancel = (param = 'cancel') =>
  compose(any(equals(false)), map(pathOr(true, ['sailRefs', [0], 'allowance', param])), prop('sailPackages'))

class RefundReason extends React.PureComponent {
  onReservationMessageAction = (index) => {
    const {
      goToEditReservation,
      startEditReservation,
      reservation,
      reason: { status },
      noRefundForRoute = false,
      isLaaksaare = false,
      outOfBookingBoundary = false,
    } = this.props

    const openEdit = () => {
      goToEditReservation({ reservation, mode: 'datetime' })
      startEditReservation(reservation.token)
    }

    const messageActions = []
    if (status === 'CANCELLED' && noRefundForRoute) {
      messageActions.push(openEdit)
    }
    if (status === 'CANCELLED' || status === 'NON_REFUNDABLE') {
      messageActions.push(openEdit)
    }
    if (status === 'CANCELLED') {
      messageActions.push(() => this.props.goToRefund(reservation))
    } else if (status === 'BOOKED_CANCELLED' && isLaaksaare && !outOfBookingBoundary) {
      messageActions.push(openEdit)
    }
    // eslint-disable-next-line babel/no-unused-expressions
    messageActions[index] && messageActions[index]()
  }

  render() {
    const {
      noRefundForRoute = false,
      isLaaksaare = false,
      outOfBookingBoundary = false,
      reason: { isBooked, status, ...reason } = {},
      reservation = {},
    } = this.props

    const allowToEdit = getReservationAllowanceProp('edit')(reservation)
    const allowToCancel = getReservationAllowanceProp('cancel')(reservation)
    const anySailNotAllowedToCancel = anySailWhichCannotCancel()(reservation)

    if (!allowToEdit && !allowToCancel) return null
    if (anySailNotAllowedToCancel) return null

    if (status === 'CANCELLED' && noRefundForRoute) {
      return (
        <ReservationMessage
          icon="stop"
          onAction={this.onReservationMessageAction}
          message={translate(messages.tripCancelledWithRefundDisabled)}
        />
      )
    }

    return (
      <Fragment>
        <ReservationMessage
          icon="stop"
          display-if={status === 'BOOKED_CANCELLED' && !isLaaksaare}
          onAction={this.onReservationMessageAction}
          message={translate(messages.bookedTripCancelled)}
        />
        <ReservationMessage
          icon="stop"
          display-if={status === 'BOOKED_CANCELLED' && isLaaksaare && !outOfBookingBoundary}
          onAction={this.onReservationMessageAction}
          message={translate(messages.tripCancelledWithRefundDisabled)}
        />
        <ReservationMessage
          icon="stop"
          display-if={status === 'CANCELLED'}
          onAction={this.onReservationMessageAction}
          message={translate(messages.tripCanRefundable)}
        />
        <ReservationMessage
          icon="stop"
          display-if={status === 'NON_REFUNDABLE'}
          onAction={this.onReservationMessageAction}
          message={translate(messages.tripNonRefundable)}
        />
        <ReservationMessage
          icon="time"
          display-if={status === 'EXPIRED'}
          onAction={this.onReservationMessageAction}
          message={translate(messages.tripImpossibleRefundable, { days: reason.daysPassed })}
        />
      </Fragment>
    )
  }
}

RefundReason.propTypes = {
  reservation: PropTypes.object.isRequired,
  goToRefund: PropTypes.func.isRequired,
  goToEditReservation: PropTypes.func.isRequired,
  reason: PropTypes.object.isRequired,
  noRefundForRoute: PropTypes.bool.isRequired,
  isLaaksaare: PropTypes.bool.isRequired,
  outOfBookingBoundary: PropTypes.bool,
}

export default RefundReason
