import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import { UILayout, UIText, UIFormattedNumber } from 'bora-material-ui'

import messages from '../../../consts/messages'

const xsStyles = {
  width100: { width: '100%' },
}

const DifferenceToPay = (
  { oldTotal, newTotal, currency, difference = Math.abs(oldTotal - newTotal) },
  { muiTheme }
) => (
  <UILayout column j-flex-space-between width="50%" data-testid="edit-ticket-total-sum" xs={xsStyles.width100}>
    <Fragment>
      <UILayout data-testid="edit-ticket-sum-row-old" j-flex-space-between>
        <UIText size="16px" color="#bfc3c7" translate={messages.oldTotal} />
        <UIText size="18px" color="black" weight="bold">
          <UIFormattedNumber style="currency" value={oldTotal} currency={currency} />
        </UIText>
      </UILayout>

      <UILayout data-testid="edit-ticket-sum-row-new" j-flex-space-between>
        <UIText size="16px" color="#bfc3c7" translate={messages.newTotal} />
        <UIText size="18px" color="black" weight="bold">
          <UIFormattedNumber style="currency" value={newTotal} currency={currency} />
        </UIText>
      </UILayout>

      <UILayout data-testid="edit-ticket-sum-row-new" j-flex-space-between margin="0 0 10px 0">
        <UIText size="20px" color="#bfc3c7" translate={messages.difToPay} />
        <UIText
          size="28px"
          color={newTotal - oldTotal > 0 ? '#b42a2a' : '#63853e'}
          weight="bold"
          font={muiTheme.secondFontFamilyDem}
        >
          {newTotal - oldTotal < 0 && '-'}
          <UIFormattedNumber style="currency" value={difference} currency={currency} />
        </UIText>
      </UILayout>
    </Fragment>
  </UILayout>
)

DifferenceToPay.contextTypes = {
  muiTheme: PropTypes.object,
}

DifferenceToPay.propTypes = {
  oldTotal: PropTypes.number.isRequired,
  newTotal: PropTypes.number.isRequired,
  difference: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
}

export default DifferenceToPay
