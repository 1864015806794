import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { pathOr, head, reduce, forEach, add, compose, map, defaultTo, reject, isEmpty } from 'ramda'
import CircularProgress from '@material-ui/core/CircularProgress'
import { UILayout, UIText, UIIcon } from 'bora-material-ui'
import { timeSort } from './AvailableTickets.util'
import { Voyages, TicketRowHeader } from './Layout'

import { selectors, actions, transformSailInventoriesToTickets } from '../../provider'
import messages from '../../../../consts/messages'
// check it
import { setEditedReservationData } from '../../../../services/reservation/actions/reservation.js'
import { setSelectedSailForEdit } from '../../../../services/schedule/actions/schedule'
import { selectedSailBySailPackageCodeWhileEdit } from '../../../../services/schedule/reducers'
import { THEME } from 'bora-material-ui/themes'
import { getSelectedSailPackageFirstRoute } from '../../../../services/user-selections'
import { getStyle, isLiinilaevad } from '../../../../utils/liinilaevadThemeUtils'
import { getGavdosStyle } from '../../../../utils/gavdosStyleUtils'

const capacityWarningNote = '*'
const noMoreLocalsTicketsNote = '**'

const getWarnings = compose(
  defaultTo(['', '']),
  head,
  reject(isEmpty),
  map(pathOr([], ['inventory', 'inventories', 'warnings']))
)

const xsStyles = {
  padding0MarginTop20: { padding: '0', margin: '20px 0 0 0' },
  marginVertical10: { margin: '10px 0' },
}

const styles = {
  warning: {
    padding: '15px 10px',
    width: 'auto',
    margin: '0 0 0 20px',
    borderBottom:
      window.brandProps.theme !== THEME.SAAREMAA &&
      window.brandProps.theme !== THEME.KIHNU &&
      !isLiinilaevad &&
      '1px solid #ddd',
  },

  smallStar: {
    margin: '0 5px 0 0',
    'font-size': '18px',
    'font-family': 'GinesoNorm',
    'font-weight': 'bold',
    color: '#d0021b',
  },

  mediumStar: {
    'font-size': '18px',
    'font-family': 'GinesoNormDem,Fjalla One',
    'font-weight': 'normal',
    color: '#f46e5a',
    margin: '0 5px 0 0',
  },

  bigStar: {
    'font-size': '28px',
  },
}

export class AvailableTickets extends React.PureComponent {
  // eslint-disable-next-line max-lines-per-function,complexity
  render() {
    const { muiTheme, intl } = this.context
    const {
      sailInventories,
      selectedSail,
      ticketsIsFetching,
      onEditMode,
      selectedRoute,
      warnings,
      getLocalsSoldOutAttribute,
      additionalStyles,
      inventoryTaken,
    } = this.props
    const availableTickets = transformSailInventoriesToTickets(sailInventories)

    // const showRedWarning = checkOutOfStock(availableTickets)

    const [warning1, warning2] = warnings

    const totallyOutOfStock = availableTickets.filter((item) => {
      const { availabilityTickets } = item
      let countOutOfStock = 0
      for (let i = 0; i < availabilityTickets.length; i += 1) {
        if (availabilityTickets[i].availability === 0) countOutOfStock += 1
      }
      return Boolean(countOutOfStock)
    })

    const showRedWarning = totallyOutOfStock.length > 0

    const voyages = timeSort(availableTickets)
    let totalReserves = 0
    const showWarningsForLocals =
      voyages.length > 0
        ? voyages.reduce(
            (acc, voyage) =>
              voyage.availabilityTickets.reduce((innerAcc, ticket) => {
                if (ticket.type === 'Passenger') {
                  totalReserves += ticket.reserves.amount
                }
                return ticket.reserves.show ? !ticket.reserves.amount || innerAcc : ticket.reserves.show || innerAcc
              }, false) || acc,
            false
          )
        : false
    const showHintAboutLocals =
      voyages.length > 0
        ? voyages.find(
            (voyage) =>
              voyage &&
              voyage.availabilityTickets &&
              voyage.availabilityTickets.some((availability) => availability.reserves && availability.reserves.show)
          )
        : false

    const checkRoute = (route) => route.startsWith('RIN-')

    const twoHoursBeforeDeparture = availableTickets.filter((item) => item.sail.status === 'CLOSED').length > 0

    const showTextForLines1 =
      selectedRoute === 'SVI-ROH-SVI' ||
      selectedRoute === 'SOR-TRI-SOR' ||
      selectedRoute === 'KIH-MUN-KIH' ||
      selectedRoute === 'KIH-PAR-KIH' ||
      selectedRoute === 'MAN-MUN-MAN'

    const mergeAvailability = (acc, { availabilityTickets }) => {
      const addToAcc = ({ type, availability }) => {
        acc[type] = add(pathOr(0, [type], acc), availability)
      }
      forEach(addToAcc, availabilityTickets)
      return acc
    }

    const voyagesAvailability = reduce(mergeAvailability, {}, voyages)
    voyagesAvailability.totalReserves = totalReserves

    const isThereCancelledSails =
      voyages.filter((voyage) => pathOr('', ['sail', 'status'])(voyage) === 'CANCELLED').length > 0
    const hasOnlyCancelledSails =
      voyages.filter((voyage) => pathOr('', ['sail', 'status'])(voyage) === 'CANCELLED').length === voyages.length

    const isThereHovercraft =
      voyages.filter((voyage) => pathOr('', ['sail', 'vesselType'])(voyage) === 'AIR_CUSHION_VESSEL').length > 0
    const hasOnlyHovercraftSails =
      voyages.filter((voyage) => pathOr('', ['sail', 'vesselType'])(voyage) === 'AIR_CUSHION_VESSEL').length ===
      voyages.length
    const hasOnlySeatsAndBikes =
      voyages.filter(
        (voyage) =>
          pathOr('', ['sail', 'vesselFeatures', 'hasBicycleSpots'])(voyage) === true &&
          pathOr('', ['sail', 'vesselFeatures', 'hasSeats'])(voyage) === true &&
          pathOr('', ['sail', 'vesselFeatures', 'hasCarDecks'])(voyage) === false &&
          pathOr('', ['sail', 'vesselFeatures', 'hasCabins'])(voyage) === false
      ).length === voyages.length ||
      (voyages.find(
        (voyage) =>
          pathOr('', ['sail', 'vesselFeatures', 'hasBicycleSpots'])(voyage) === true &&
          pathOr('', ['sail', 'vesselFeatures', 'hasSeats'])(voyage) === true &&
          pathOr('', ['sail', 'vesselFeatures', 'hasCarDecks'])(voyage) === false &&
          pathOr('', ['sail', 'vesselFeatures', 'hasCabins'])(voyage) === false
      ) &&
        !voyages.find(
          (voyage) =>
            pathOr('', ['sail', 'vesselFeatures', 'hasBicycleSpots'])(voyage) === false &&
            pathOr('', ['sail', 'vesselFeatures', 'hasSeats'])(voyage) === true &&
            pathOr('', ['sail', 'vesselFeatures', 'hasCarDecks'])(voyage) === true &&
            pathOr('', ['sail', 'vesselFeatures', 'hasCabins'])(voyage) === false
        ) &&
        !voyages.find(
          (voyage) =>
            pathOr('', ['sail', 'vesselFeatures', 'hasBicycleSpots'])(voyage) === true &&
            pathOr('', ['sail', 'vesselFeatures', 'hasSeats'])(voyage) === true &&
            pathOr('', ['sail', 'vesselFeatures', 'hasCarDecks'])(voyage) === true &&
            pathOr('', ['sail', 'vesselFeatures', 'hasCabins'])(voyage) === false
        ))
    const hasOnlySeatsAndCars =
      voyages.filter(
        (voyage) =>
          pathOr('', ['sail', 'vesselFeatures', 'hasBicycleSpots'])(voyage) === false &&
          pathOr('', ['sail', 'vesselFeatures', 'hasSeats'])(voyage) === true &&
          pathOr('', ['sail', 'vesselFeatures', 'hasCarDecks'])(voyage) === true &&
          pathOr('', ['sail', 'vesselFeatures', 'hasCabins'])(voyage) === false
      ).length === voyages.length ||
      (voyages.find(
        (voyage) =>
          pathOr('', ['sail', 'vesselFeatures', 'hasBicycleSpots'])(voyage) === false &&
          pathOr('', ['sail', 'vesselFeatures', 'hasSeats'])(voyage) === true &&
          pathOr('', ['sail', 'vesselFeatures', 'hasCarDecks'])(voyage) === true &&
          pathOr('', ['sail', 'vesselFeatures', 'hasCabins'])(voyage) === false
      ) &&
        !voyages.find(
          (voyage) =>
            pathOr('', ['sail', 'vesselFeatures', 'hasBicycleSpots'])(voyage) === true &&
            pathOr('', ['sail', 'vesselFeatures', 'hasSeats'])(voyage) === true &&
            pathOr('', ['sail', 'vesselFeatures', 'hasCarDecks'])(voyage) === false &&
            pathOr('', ['sail', 'vesselFeatures', 'hasCabins'])(voyage) === false
        ) &&
        !voyages.find(
          (voyage) =>
            pathOr('', ['sail', 'vesselFeatures', 'hasBicycleSpots'])(voyage) === true &&
            pathOr('', ['sail', 'vesselFeatures', 'hasSeats'])(voyage) === true &&
            pathOr('', ['sail', 'vesselFeatures', 'hasCarDecks'])(voyage) === true &&
            pathOr('', ['sail', 'vesselFeatures', 'hasCabins'])(voyage) === false
        ))
    const hasOnlyPassengers =
      voyages.filter(
        (voyage) =>
          pathOr('', ['sail', 'vesselFeatures', 'hasBicycleSpots'])(voyage) === false &&
          pathOr('', ['sail', 'vesselFeatures', 'hasSeats'])(voyage) === true &&
          pathOr('', ['sail', 'vesselFeatures', 'hasCarDecks'])(voyage) === false &&
          pathOr('', ['sail', 'vesselFeatures', 'hasCabins'])(voyage) === false
      ).length === voyages.length

    const { onSelectTicket, onSelectTicketWhileEdit } = this.props
    const handleSelectSail = onEditMode ? onSelectTicketWhileEdit : onSelectTicket

    return (
      <UILayout column display-if={voyages}>
        {ticketsIsFetching ? (
          <UILayout column width="auto" align="center" padding="20px">
            <CircularProgress />
          </UILayout>
        ) : (
          <UILayout
            data-testid="sail-select-table"
            column
            margin="-40px 0 0 0"
            padding="0 0 0 20px"
            sm={xsStyles.padding0MarginTop20}
            {...additionalStyles}
          >
            {/* Ticket items header */}
            <UILayout display-if={head(voyages)} row padding={getGavdosStyle('0 0 40px 0', '0 0 10px 0')}>
              <TicketRowHeader
                availability={voyagesAvailability}
                hasOnlySeatsAndBikes={hasOnlySeatsAndBikes}
                hasOnlyPassengers={hasOnlyHovercraftSails || hasOnlyPassengers}
                hasOnlySeatsAndCars={hasOnlySeatsAndCars}
              />
            </UILayout>
            <Voyages
              voyages={voyages}
              selectedSail={selectedSail}
              onSelect={handleSelectSail}
              onEditMode={onEditMode}
              setEditedReservationData={this.props.setEditedReservationData}
              route={selectedRoute}
              inventoryTaken={inventoryTaken}
              hasOnlyPassengers={hasOnlyHovercraftSails || hasOnlyPassengers}
              hasOnlySeatsAndCars={hasOnlySeatsAndCars}
            />
          </UILayout>
        )}

        {/* Note about tickets */}

        <UILayout
          display-if={
            (checkRoute(selectedRoute) || showHintAboutLocals) &&
            sailInventories.length > 0 &&
            voyages.length > 0 &&
            !hasOnlyCancelledSails
          }
          j-flex-start
          maxWidth="100%"
          padding="7px 36px 0 24px"
          sm={xsStyles.marginVertical10}
          column
          style={styles.warning}
        >
          <UIText
            color="#94a8b2"
            align="left"
            textTransform="uppercase"
            weight="bold"
            font={muiTheme.secondFontFamilyDem}
            size="14px"
            translate={messages.noteAboutTicketPart4}
          />
        </UILayout>

        <UILayout
          data-testid="departure-warnings"
          display-if={twoHoursBeforeDeparture}
          j-flex-start
          padding="7px 36px 0 24px"
          sm={xsStyles.marginVertical10}
          column
          style={styles.warning}
        >
          <UIText
            display-if={false}
            color="#d0021b"
            align="left"
            textTransform="uppercase"
            weight="bold"
            font={muiTheme.secondFontFamilyDem}
            size="14px"
            translate={messages.warningText1Part1}
            margin="0 0 5px 0"
          />
        </UILayout>

        <UILayout
          display-if={!isThereCancelledSails && showRedWarning && (warning1 || warning2)}
          j-flex-start
          maxWidth="100%"
          padding="7px 36px 0 24px"
          sm={xsStyles.marginVertical10}
          column
          style={styles.warning}
        >
          <UIText
            display-if={warning1}
            color="#f46e5a"
            align="left"
            textTransform="uppercase"
            weight="bold"
            font={muiTheme.secondFontFamilyDem}
            size="14px"
          >
            {<span style={{ ...styles.mediumStar, ...styles.bigStar }}>{capacityWarningNote}</span>}
            {warning1}
          </UIText>

          <UIText
            display-if={showTextForLines1 && warning2}
            color="#f46e5a"
            align="left"
            textTransform="uppercase"
            weight="bold"
            font={muiTheme.secondFontFamilyDem}
            size="14px"
            margin="10px 0 0 0"
          >
            {warning2}
          </UIText>
        </UILayout>

        <UILayout
          display-if={showWarningsForLocals && getLocalsSoldOutAttribute(intl.locale.toUpperCase())}
          j-flex-start
          maxWidth="100%"
          padding="7px 36px 0 24px"
          sm={xsStyles.marginVertical10}
          column
          style={styles.warning}
        >
          <UIText
            color="#f46e5a"
            align="left"
            textTransform="uppercase"
            weight="bold"
            font={muiTheme.secondFontFamilyDem}
            size="14px"
          >
            {<span style={styles.mediumStar}>{noMoreLocalsTicketsNote}</span>}
            {getLocalsSoldOutAttribute(intl.locale.toUpperCase())
              ? getLocalsSoldOutAttribute(intl.locale.toUpperCase()).value
              : ''}
          </UIText>
        </UILayout>
        <UILayout
          display-if={isThereHovercraft}
          data-testid="note-about-dangerous"
          margin="7px 0 0 20px"
          center
          style={styles.warning}
        >
          <UIIcon type="iconHovercraft" data-testid="hovercraft-icon" width="25px" height="25px" margin="0 10px 0 0" />
          <UIText
            size={getStyle(onEditMode ? '18px' : '14px', '14px')}
            weight={'bold'}
            color={'#2E8DD9'}
            textTransform="uppercase"
            font={muiTheme.name === THEME.SAAREMAA ? muiTheme.thirdFontFamily : muiTheme.secondFontFamilyDem}
            translate={messages.noteAboutHovercraft}
          />
        </UILayout>
        <UILayout
          display-if={this.isDangerous()}
          data-testid="note-about-dangerous"
          margin="7px 0 0 20px"
          center
          style={styles.warning}
        >
          <UIIcon type="iconDanger" data-testid="danger-icon" width="25px" height="25px" margin="0 10px 0 0" />
          <UIText
            size={getStyle(onEditMode ? '18px' : '14px', '14px')}
            color={
              muiTheme.palette && muiTheme.palette.warningSecondary ? muiTheme.palette.warningSecondary : '#ff5a19'
            }
            weight={'bold'}
            textTransform="uppercase"
            font={muiTheme.name === THEME.SAAREMAA ? muiTheme.thirdFontFamily : muiTheme.secondFontFamilyDem}
            translate={messages.noteAboutDangerous}
          />
        </UILayout>

        <UILayout
          display-if={
            this.isRestrictedPrice() && muiTheme.name !== THEME.SAAREMAA && !hasOnlySeatsAndBikes && !hasOnlyPassengers
          }
          data-testid="note-about-dangerous"
          margin="7px 0 0 20px"
          center
          style={styles.warning}
        >
          <UIIcon
            type="iconNoHeavyTrucks"
            data-testid="no-heavy-trucks-icon"
            width="25px"
            height="25px"
            margin="0 10px 0 0"
          />
          <UIText
            size={getStyle(onEditMode ? '18px' : '14px', '14px')}
            color="#000"
            textTransform="uppercase"
            font={muiTheme.secondFontFamilyDem}
            translate={messages.noteNoHeavyTrucks}
          />
        </UILayout>

        <UILayout
          display-if={isThereCancelledSails}
          data-testid="note-about-dangerous"
          margin="7px 0 0 20px"
          center
          style={styles.warning}
        >
          <UIIcon
            type="iconCancelledSail"
            width="25px"
            height="25px"
            margin="0 10px 0 0"
            data-testid="cancelled-icon"
          />
          <UIText
            size={getStyle(onEditMode ? '18px' : '14px', '14px')}
            weight={'bold'}
            color={muiTheme.palette && muiTheme.palette.errorSecondary ? muiTheme.palette.errorSecondary : '#b42a2a'}
            textTransform="uppercase"
            font={muiTheme.name === THEME.SAAREMAA ? muiTheme.thirdFontFamily : muiTheme.secondFontFamilyDem}
            translate={messages.noteAboutCancelled}
          />
        </UILayout>
      </UILayout>
    )
  }

  isDangerous() {
    const { sailInventories } = this.props
    return sailInventories.some(
      (inventory) =>
        inventory.sail.dangerousGoods &&
        inventory.inventory &&
        inventory.inventory.inventories &&
        inventory.inventory.inventories.enabled
    )
  }

  isRestrictedPrice() {
    const { sailInventories } = this.props
    return sailInventories.some(
      (inventory) =>
        inventory.sail.restrictedPrices &&
        inventory.inventory &&
        inventory.inventory.inventories &&
        inventory.inventory.inventories.enabled
    )
  }
}

AvailableTickets.contextTypes = {
  muiTheme: PropTypes.object,
  intl: PropTypes.object,
}

AvailableTickets.propTypes = {
  ticketsIsFetching: PropTypes.bool,
  sailInventories: PropTypes.array,
  selectedSail: PropTypes.object,
  onSelectTicket: PropTypes.func,
  onSelectTicketWhileEdit: PropTypes.func,
  isDangerous: PropTypes.bool,
  isRestrictedPrice: PropTypes.bool,
  onEditMode: PropTypes.bool,
  additionalStyles: PropTypes.object,
  selectedRoute: PropTypes.any.isRequired,
  warnings: PropTypes.arrayOf(PropTypes.string).isRequired,
}

AvailableTickets.defaultProps = {
  sailInventories: [],
  selectedSail: {},
  onSelectTicket: () => {},
  onSelectTicketWhileEdit: () => {},
  ticketsIsFetching: false,
  onEditMode: false,
  additionalStyles: {},
}

const makeMapStateToProps = () => {
  const getSelectedSailPackageCode = selectors.getSelectedSailPackageCodeFromProps()

  const mapStateToProps = (state, props) => {
    const selectedSailPackageCode = getSelectedSailPackageCode(state, props)
    const { sailPackage } = props
    const availableDates = selectors.selectScheduleState(state).availableDates[selectedSailPackageCode]
    const sailInventories = selectors.sailInventoriesBySailPackageCode(selectedSailPackageCode)(state)
    const selectedSail = props.onEditMode
      ? selectedSailBySailPackageCodeWhileEdit(state)(selectedSailPackageCode, props.isCancelled)
      : selectors.selectedSailBySailPackageCode(state)(selectedSailPackageCode)
    const getLocalsSoldOutAttribute = (locale) =>
      map(
        (item) => ({
          code: item.attribute.code,
          value: pathOr('', ['structure', 'value', 'value'])(item) || pathOr('', ['structure', 'values'])(item),
        }),
        pathOr([], ['routes', `${getSelectedSailPackageFirstRoute(state)}`, 'attributes'])(state)
      ).find((item) => item.code === `ONLINE_SOLD_OUT_INFO_RESIDENT_${locale}`)
    return {
      ticketsIsFetching: selectors.getTicketsIsFetching(state),
      selectedSail,
      sailInventories,
      showAvailableTickets: availableDates ? availableDates[selectors.getSailDate(state)] : false,
      selectedRoute: pathOr('', ['route'], sailPackage),
      warnings: getWarnings(sailInventories || []),
      getLocalsSoldOutAttribute,
    }
  }
  return mapStateToProps
}

export default connect(makeMapStateToProps, {
  onSelectTicket: actions.setSelectedSail,
  onSelectTicketWhileEdit: setSelectedSailForEdit,
  setEditedReservationData,
})(AvailableTickets)
