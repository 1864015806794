import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'ramda'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { UICheckbox, UILayout, UIText } from 'bora-material-ui'
import { getFormValues, change, reduxForm } from 'redux-form'
import messages from '../../consts/messages'
import { getSelectedSailPackageFirstRoute } from '../../services/user-selections'
import { getBookingConditionsForRoute } from '../../services/reservation/selectors'
import purify from 'dompurify'

const FORM_NAME = 'subInfo'

const styles = {
  label: { size: '14px', fontWeight: 'normal', color: '#858585' },
  labelIcon: { marginRight: '10px', fill: '#0eb1bc' },
}

const SubInfoHeaderSpan = styled.span`
  text-transform: uppercase;
`

const SubInfoText = styled.div`
  text-align: justify;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
`

export function SubInfo({ hidden, changeReduxFormField }, { muiTheme, intl }) {
  const { subInfoBgColor, secondFontFamilyDem, fontFamily } = muiTheme
  const textColor = 'black'
  const handleCheck = (event, value) => changeReduxFormField(FORM_NAME, 'hide', value)
  if (hidden) return null

  const linkTagToInsert = `<a target="_blank" href="${location.origin}/#/${intl.locale}/content/schedules/LAA-PII">$1</a>`
  const subInfoText = intl.formatMessage(messages.reservationOnlySailText).replace(/#(\w+)#/gi, linkTagToInsert)
  const html = { __html: purify.sanitize(subInfoText) }

  return (
    <UILayout column bgColor={subInfoBgColor} center padding="20px 20px" data-testid="sub-info">
      <UIText background={subInfoBgColor} size="18px" color="black" font={secondFontFamilyDem} c>
        <SubInfoHeaderSpan>{intl.formatMessage(messages.reservationOnlySailHeader)}</SubInfoHeaderSpan>
      </UIText>
      <UILayout column>
        <UIText font={fontFamily} color={textColor} size="16px" padding="5px 0">
          <SubInfoText dangerouslySetInnerHTML={html}></SubInfoText>
        </UIText>
        <UILayout>
          <UICheckbox
            labelStyle={styles.label}
            onCheck={handleCheck}
            label={intl.formatMessage(messages.reservationOnlySailCheckLabel)}
            iconStyle={styles.labelIcon}
          />
        </UILayout>
      </UILayout>
    </UILayout>
  )
}

SubInfo.contextTypes = {
  muiTheme: PropTypes.object,
  intl: PropTypes.object,
}

SubInfo.propTypes = {
  hidden: PropTypes.bool,
  isLaaksaare: PropTypes.bool,
  outOfBookingBoundary: PropTypes.bool,
  changeReduxFormField: PropTypes.func.isRequired,
}

const formConfig = {
  form: FORM_NAME,
  initialValues: {
    hide: false,
  },
  destroyOnUnmount: false,
}

const mapDispatchToProps = {
  changeReduxFormField: change,
}

const mapStateToProps = (state) => ({
  hidden: (getFormValues(FORM_NAME)(state) || {}).hide || true,
  isLaaksaare: getSelectedSailPackageFirstRoute(state).startsWith('LAA-'),
  outOfBookingBoundary: getBookingConditionsForRoute(state),
})

export default compose(connect(mapStateToProps, mapDispatchToProps), reduxForm(formConfig))(SubInfo)
