import React from 'react'
import PropTypes from 'prop-types'
import { pathOr, values, head, keys, compose } from 'ramda'
import { UILayout, UIText, UIFormattedNumber, UIIcon } from 'bora-material-ui'

import { ReservationContext } from '../ConfirmPay'
import { THEME } from 'bora-material-ui/themes'
import { getStyle, isLiinilaevad } from '../../../../../../../utils/liinilaevadThemeUtils'
import { getGavdosStyle } from '../../../../../../../utils/gavdosStyleUtils'

const styles = {
  fontSize15: { fontSize: '15px' },
}

export const CUSTOM_WEIGHT_FILL_COLOR = '#ff'

const aroundPriceWrapperStyle = { minWidth: '2rem', textAlign: 'right' }

export const EditWeightPencil = ({ margin = '10px 0' }) => (
  <UIIcon type="iconPencil" width="14px" height="14px" fill={CUSTOM_WEIGHT_FILL_COLOR} margin={margin} />
)

export const CustomWeightControl = ({ showModal, setModalData, index = 0, customWeightPossibility, vehicleData }) => (
  <UILayout data-testid="editWeightPencilIcon">
    <UIText
      ellipsis={false}
      whiteSpace="normal"
      align="left"
      color={CUSTOM_WEIGHT_FILL_COLOR}
      size="14px"
      margin="0 5px"
      display-if={vehicleData.customWeight && index === 0 && customWeightPossibility}
    >
      ({vehicleData.customWeight}kg)
    </UIText>
    <UILayout
      width="30px"
      display-if={index === 0 && customWeightPossibility}
      cursor="pointer"
      onClick={() => {
        showModal('addCustomVehicleWeight')
        setModalData(vehicleData)
      }}
    >
      <EditWeightPencil />
    </UILayout>
  </UILayout>
)

const RowInfo = ({
  isHeader,
  labels,
  currency,
  showModal,
  item = {},
  setModalData,
  findWeight,
  customWeightMap = {},
  hideZeroPrice = false,
}) => {
  let rowTextColor = isHeader ? getStyle('#A8A8A8', '#bfc3c7') : '#000000'

  if (item.promotion) {
    rowTextColor = getGavdosStyle('#44944a', '#7ed321')
  }

  const customWeightPossibility = customWeightMap[item.priceCategory || '']
  const generateXsLayout = (index) => {
    if (window.brandProps.theme === THEME.SAAREMAA || window.brandProps.theme === THEME.KIHNU) {
      return index === 2 ? 0 : 1
    }
    return index > 2 ? 0 : 1
  }
  let seqNum
  let sailRefId
  let customWeight = 0
  let formerWeight = 0

  if (customWeightPossibility) {
    seqNum = pathOr(0, ['ownerSeqNs', 0])(item)
    sailRefId = compose(head, keys)(item.sailRefIdToPricePerSail)
    const { custom = 0, former = 0 } = findWeight(seqNum, parseInt(sailRefId, 10))

    customWeight = custom
    formerWeight = former
  }

  const vehicleData = {
    seqNum,
    sailRefId,
    customWeight,
    formerWeight,
  }

  const normalMargin = item.promotion ? '-1.5rem 0 20px 0' : '0 0 20px 0'

  return (
    <UILayout center j-flex-space-between margin={isHeader ? '0px 0px 27px 0px' : normalMargin} data-testid="row-info">
      {labels.map((label, index) => (
        <UILayout
          xs={generateXsLayout(index)}
          minWidth={index === 0 ? '50%' : ''}
          justify={index === 0 ? 'flex-start' : 'center'}
        >
          <UIText
            width={window.brandProps.theme === THEME.SAAREMAA && isHeader && index === 0 && '50%'}
            ellipsis={false}
            whiteSpace={getStyle('nowrap', item.promotion || isHeader ? 'nowrap' : 'normal')}
            font={getStyle('SourceSansPro, Sans-Serif')}
            align="left"
            size={getStyle('18px')}
            color={rowTextColor}
            xs={{
              whiteSpace: isHeader && isLiinilaevad ? 'nowrap' : 'normal',
              align: index === 0 ? 'left' : 'center',
              size: getStyle('18px', '14px'),
            }}
            {...(window.brandProps.muiTheme.ids.Confirmation &&
              window.brandProps.muiTheme.ids.Confirmation.rowText && {
                ...window.brandProps.muiTheme.ids.Confirmation.rowText,
              })}
          >
            {typeof label === 'number' && index > 1 && !isHeader ? (
              <div style={aroundPriceWrapperStyle}>
                <UIFormattedNumber
                  display-if={!(hideZeroPrice && label <= 0)}
                  value={label}
                  style="currency"
                  currency={currency}
                  minimumFractionDigits={0}
                  adjustStyles={styles.fontSize15}
                />
              </div>
            ) : index === 1 && item.promotion ? (
              ''
            ) : (
              label
            )}
          </UIText>
          <CustomWeightControl
            vehicleData={vehicleData}
            setModalData={setModalData}
            showModal={showModal}
            index={index}
            customWeightPossibility={customWeightPossibility}
          />
        </UILayout>
      ))}
    </UILayout>
  )
}

RowInfo.propTypes = {
  isHeader: PropTypes.bool.isRequired,
  labels: PropTypes.array.isRequired,
  customWeightPossibility: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  setModalData: PropTypes.func.isRequired,
  findWeight: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  hideZeroPrice: PropTypes.bool.isRequired,
}

const SumRow = ({ label, sum, subTotal, currencyCode }) => (
  <UILayout j-flex-between margin="9px 0">
    <UIText
      color={getStyle('#A8A8A8', '#bfc3c7')}
      size={getStyle('18px', '16px')}
      font={getStyle('SourceSansPro, Sans-Serif')}
      align="left"
      width="50%"
      {...(window.brandProps.muiTheme.ids.Confirmation &&
        window.brandProps.muiTheme.ids.Confirmation.rowText && {
          ...window.brandProps.muiTheme.ids.Confirmation.rowText,
        })}
    >
      {label}
    </UIText>
    {subTotal}
    <UIText
      color="black"
      size={getStyle('18px', '16px')}
      align="right"
      width="50%"
      weight={subTotal ? 'bold' : 'normal'}
      font={getStyle(
        'SourceSansPro, Sans-Serif',
        getGavdosStyle(
          'FiraSans, FiraSansBold',
          window.brandProps.muiTheme.ids.Confirmation &&
            window.brandProps.muiTheme.ids.Confirmation.rowText &&
            window.brandProps.muiTheme.ids.Confirmation.rowText.font
        )
      )}
    >
      <UIFormattedNumber display-if={sum || sum === 0} value={sum} style="currency" currency={currencyCode} />
    </UIText>
  </UILayout>
)

SumRow.propTypes = {
  currencyCode: PropTypes.string.isRequired,
  label: PropTypes.any.isRequired,
  sum: PropTypes.any.isRequired,
  subTotal: PropTypes.bool,
}

SumRow.defaultProps = {
  subTotal: false,
}

class TableRow extends React.PureComponent {
  render() {
    const { item, localTravellerInfo, showModal, setModalData, findWeight, customWeightMap, hideZeroPrice } = this.props

    return (
      <ReservationContext.Consumer>
        {(reservation) => {
          const currency = pathOr('eur', ['paymentInfo', 'totalPrice', 'currency'], reservation)
          const locals = values(localTravellerInfo)

          const thisLocalCategory = locals.filter((category) => category.level === item.priceCategory)

          const localTitle = thisLocalCategory.length > 0 ? `${thisLocalCategory[0].priceCategoryTitle} (local)` : ''

          const title = item.priceCategoryTranslation || item.title || localTitle
          const labels = [title, item.quantity, item.pricePerUnit, item.price]

          return (
            <RowInfo
              labels={labels}
              currency={currency}
              showModal={showModal}
              setModalData={setModalData}
              item={item}
              findWeight={findWeight}
              customWeightMap={customWeightMap}
              hideZeroPrice={hideZeroPrice}
            />
          )
        }}
      </ReservationContext.Consumer>
    )
  }
}

TableRow.propTypes = {
  item: PropTypes.object.isRequired,
  localTravellerInfo: PropTypes.object,
  showModal: PropTypes.func.isRequired,
  findWeight: PropTypes.func.isRequired,
  setModalData: PropTypes.func.isRequired,
  hideZeroPrice: PropTypes.bool.isRequired,
}
TableRow.defaultProps = {
  localTravellerInfo: {},
  hideZeroPrice: false,
}

export { RowInfo, SumRow, TableRow }
